import React, { useState, useEffect } from "react"
import {
  Card,
  Spin,
  Form,
  Image,
  Button,
  Upload,
  Input,
  Col,
  Row,
  Popconfirm,
  Descriptions,
} from "antd"
import * as Http from "utils/http.helper"
import toastr from "toastr"
import { useLocation, useNavigate } from "react-router-dom"
import { ClearOutlined, UploadOutlined } from "@ant-design/icons"
import SvgComponent from "components/svgComponent"
import Breadcrumbs from "components/Common/Breadcrumb"
import "toastr/build/toastr.min.css"
import Sounds from "utils/sounds"
const { TextArea } = Input

const css = `
.ant-descriptions-row{
  background:#eaeaea
}
.img_card2{
  // background:#d5d5d5!important;
  height:170px;
}
.img_card2 .ant-card-body{
  display: flex;
  padding: 5px 10px !important;
  height: 100%;
  max-height: 100px;
}
.img_card2 .ant-card-body .ant-image {
  margin:auto!important;
}
.img_card{
  background:#d5d5d5!important;
  margin-bottom:5px;
}
.img_card .ant-card-body{
  display: block;
  padding: 5px 10px !important;
}
.ant-space-item:first-child, .ant-space-item:nth-child(2){
  width:48%;
}
.ant-select-single{
  height:37px!important
}
.ant-color-picker-trigger{
  width:100%;
  justify-content: start;
  padding-left: 15px;
}
.home__icon  path{
  stroke: black;
}

.home__icon  path {
  fill:grey!important;
 }
 .ant-upload.ant-upload-select,  .ant-upload.ant-upload-select .ant-upload , .ant-upload.ant-upload-select .ant-upload button{
  width:100%!important
 }
`

const DesignUpload = () => {
  const location = useLocation()
  const state = location.state
  const [refForm] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [deleteBtn, setDeleteBtn] = useState(false)
  const navigate = useNavigate()
  const [leatherPosition, setLeatherPosition] = useState([])
  // Bana gelen veriler
  const [frontLeatherData, setFrontLeatherData] = useState([])
  const [backLeatherData, setBackLeatherData] = useState([])
  const [insideLeatherData, setInsideLeatherData] = useState([])
  const [spinToolsLeatherData, setSpinToolsLeatherData] = useState([])
  const [stonesGuitarLeatherData, setStonesGuitarLeatherData] = useState([])
  // Bana gelen veriler

  //ekranda eklenen veriler
  const [frontData, setFrontData] = useState([])
  const [backData, setBackData] = useState([])
  const [interriorData, setInterriorData] = useState([])
  const [spineData, setSpineData] = useState([])
  const [toolsData, setToolsData] = useState([])
  const [stonesData, setStonesData] = useState([])
  const [guitarData, setGuitarData] = useState([])
  //ekranda eklenen veriler
  const [saveData, setSaveData] = useState([])

  useEffect(() => {
    let arr = []
    if (frontData.length > 0) {
      frontData.map(item => arr.push(item))
    }
    if (backData.length > 0) {
      backData.map(item => arr.push(item))
    }
    if (interriorData.length > 0) {
      interriorData.map(item => arr.push(item))
    }
    if (toolsData.length > 0) {
      toolsData.map(item => arr.push(item))
    }
    if (spineData.length > 0) {
      spineData.map(item => arr.push(item))
    }
    if (guitarData.length > 0) {
      guitarData.map(item => arr.push(item))
    }
    if (stonesData.length > 0) {
      stonesData.map(item => arr.push(item))
    }
    arr.length > 0 && setSaveData(arr)
  }, [
    frontData,
    backData,
    interriorData,
    spineData,
    toolsData,
    guitarData,
    stonesData,
  ])

  function compare(a, b) {
    return a.value - b.value
  }

  useEffect(() => {
    setLoading(true)
    Http.get(`api/orders/getLeatherPositions`)
      .then(res => {
        let arr = []

        res.data.map(item => {
          arr.push({ value: item.id, label: item.name, code: item.code })
        })

        arr.sort(compare)
        setLeatherPosition(arr)
        setLoading(false)
      })
      .catch(function (error) {
        setLoading(false)
      })
  }, [])

  const getSkuImage = () => {
    setLoading(true)
    Http.get(`api/orders/GetEngravingTemplateImages?sku=${state?.sku}`)
      .then(res => {
        if (res.data.length > 0) {
          setLoading(false)
          let frontValue = []
          let backValue = []
          let insideValue = []
          let spinToolsValue = []
          let stonesGuitarValue = []
          res.data.map(item => {
            if (item.type === 10) {
              frontValue.push(item)
            } else if (item.type === 20) {
              backValue.push(item)
            } else if (item.type === 30) {
              insideValue.push(item)
            } else if (item.type === 40 || item.type === 50) {
              spinToolsValue.push(item)
            } else if (item.type === 60 || item.type === 70) {
              stonesGuitarValue.push(item)
            }
          })
          setFrontLeatherData(frontValue)
          setBackLeatherData(backValue)
          setInsideLeatherData(insideValue)
          setSpinToolsLeatherData(spinToolsValue)
          setStonesGuitarLeatherData(stonesGuitarValue)
        } else {
          setLoading(false)
        }
      })
      .catch(function (error) {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (state?.sku) {
      getSkuImage()
    }
  }, [state?.sku])
  useEffect(() => {
    if (leatherPosition.length > 0) {
      Http.get(
        `api/orders/GetEngravingOrderImages?orderId=${state?.id}${
          state?.customerId ? `&customerId=${state.customerId}` : ""
        }`
      ).then(res => {
        if (res.data.length > 0) {
          refForm.setFieldsValue({
            notes: res?.data[0]?.note,
          })
          let frontImages = []
          let stonesImages = []
          let spineImages = []
          let interiorImages = []
          let backImages = []
          res?.data.map(item => {
            item.positions.map((x, i) => {
              let position = leatherPosition.find(y => y.value === x.positionId)
              //Burada gelen type göre datayı oluşturup silme işlemleri eklenecek.
              if (item.type === 10) {
                frontImages.push({
                  original: true,
                  id: x.positionId + "_" + i + "_" + x.positionCode,
                  type: 10,
                  position: position,
                  files: {
                    uid: x.positionId + "_" + i + "_" + x.positionCode,
                    url: x.imgUrl,
                  },
                })
              } else if (item.type === 20) {
                //back
                backImages.push({
                  original: true,
                  id: x.positionId + "_" + i + "_" + x.positionCode,
                  type: 20,
                  position: position,
                  files: {
                    uid: x.positionId + "_" + i + "_" + x.positionCode,
                    url: x.imgUrl,
                  },
                })
              } else if (item.type === 30) {
                //interior
                interiorImages.push({
                  original: true,
                  id: x.positionId + "_" + i + "_" + x.positionCode,
                  type: 30,
                  position: position,
                  files: {
                    uid: x.positionId + "_" + i + "_" + x.positionCode,
                    url: x.imgUrl,
                  },
                })
              } else if (item.type === 40) {
                //spine
                spineImages.push({
                  original: true,
                  id: x.positionId + "_" + i + "_" + x.positionCode,
                  type: 40,
                  position: position,
                  files: {
                    uid: x.positionId + "_" + i + "_" + x.positionCode,
                    url: x.imgUrl,
                  },
                })
              } else if (item.type === 50) {
                //toolsData
              } else if (item.type === 60) {
                stonesImages.push({
                  original: true,
                  id: x.positionId + "_" + i + "_" + x.positionCode,
                  type: 60,
                  position: position,
                  files: {
                    uid: item.positionId + "_" + i + "_" + x.positionCode,
                    url: x.imgUrl,
                  },
                })
              } else if (item.type === 70) {
                // guitarData
              }
            })
          })

          frontImages.length > 0 && setFrontData(frontImages)
          stonesImages.length > 0 && setStonesData(stonesImages)
          spineImages.length > 0 && setSpineData(spineImages)
          interiorImages.length > 0 && setInterriorData(interiorImages)
          backImages.length > 0 && setBackData(backImages)
        }
      })
    }
  }, [leatherPosition])

  const handleAcceptedFiles = (files, values, position) => {
    if (files.name) {
      let data = []
      if (values.type === 10) {
        if (frontData.length > 0) {
          frontData.map(item => {
            data.push({
              original: item.original,
              id: item.id,
              type: item.type,
              position: item.position,
              files: item.files,
            })
          })
          data.push({
            id: values.id,
            type: values.type,
            position: position,
            files: files,
          })
        } else {
          data.push({
            id: values.id,
            type: values.type,
            position: position,
            files: files,
          })
        }
        setFrontData(data)
      } else if (values.type === 20) {
        if (backData.length > 0) {
          backData.map(item => {
            data.push({
              original: item.original,
              id: item.id,
              type: item.type,
              position: item.position,
              files: item.files,
            })
          })
          data.push({
            id: values.id,
            type: values.type,
            position: position,
            files: files,
          })
        } else {
          data.push({
            id: values.id,
            type: values.type,
            position: position,
            files: files,
          })
        }
        setBackData(data)
      } else if (values.type === 30) {
        if (interriorData.length > 0) {
          interriorData.map(item => {
            data.push({
              original: item.original,
              id: item.id,
              type: item.type,
              position: item.position,
              files: item.files,
            })
          })
          data.push({
            id: values.id,
            type: values.type,
            position: position,
            files: files,
          })
        } else {
          data.push({
            id: values.id,
            type: values.type,
            position: position,
            files: files,
          })
        }
        setInterriorData(data)
      } else if (values.type === 40) {
        if (spineData.length > 0) {
          spineData.map(item => {
            data.push({
              original: item.original,
              id: item.id,
              type: item.type,
              position: item.position,
              files: item.files,
            })
          })
          data.push({
            id: values.id,
            type: values.type,
            position: position,
            files: files,
          })
        } else {
          data.push({
            id: values.id,
            type: values.type,
            position: position,
            files: files,
          })
        }
        setSpineData(data)
      } else if (values.type === 50) {
        if (toolsData.length > 0) {
          toolsData.map(item => {
            data.push({
              original: item.original,
              id: item.id,
              type: item.type,
              position: item.position,
              files: item.files,
            })
          })
          data.push({
            id: values.id,
            type: values.type,
            position: position,
            files: files,
          })
        } else {
          data.push({
            id: values.id,
            type: values.type,
            position: position,
            files: files,
          })
        }
        setToolsData(data)
      } else if (values.type === 60) {
        if (stonesData.length > 0) {
          stonesData.map(item => {
            data.push({
              original: item.original,
              id: item.id,
              type: item.type,
              position: item.position,
              files: item.files,
            })
          })
          data.push({
            id: values.id,
            type: values.type,
            position: position,
            files: files,
          })
        } else {
          data.push({
            id: values.id,
            type: values.type,
            position: position,
            files: files,
          })
        }
        setStonesData(data)
      } else if (values.type === 70) {
        if (guitarData.length > 0) {
          guitarData.map(item => {
            data.push({
              original: item.original,
              id: item.id,
              type: item.type,
              position: item.position,
              files: item.files,
            })
          })
          data.push({
            id: values.id,
            type: values.type,
            position: position,
            files: files,
          })
        } else {
          data.push({
            id: values.id,
            type: values.type,
            position: position,
            files: files,
          })
        }
        setGuitarData(data)
      }
    }
  }
  const handleSave = () => {
    const formData = new FormData()
    const formValues = refForm.getFieldsValue()
    formData.append("OrderId", state?.id)
    formData.append("Note", formValues.notes ? formValues.notes : "")
    formData.append(
      "IsWaitingForDesign",
      state?.waitingList ? state.waitingList : false
    )
    let saveNewData = []

    saveData.map((item, index) => {
      if (!item?.original && item.files) {
        saveNewData.push(item)
      }
    })
    if (saveNewData.length > 0) {
      saveNewData.map((item, index) => {
        formData.append(`ImageTypes[${index}].type`, item.type)
        formData.append(
          `ImageTypes[${index}].positions[0].code`,
          item.position.code
        )
        formData.append(`ImageTypes[${index}].positions[0].image`, item.files)
      })

      Http.post_form_data("api/orders/AddEngravingOrderImages", formData)
        .then(res => {
          if (res.success) {
            toastr.success(
              `Image upload for sku number ${state?.sku} was successful.`
            )
            setTimeout(() => {
              setLoading(false)
              navigate(
                state?.waitingList ? "/waiting-for-desing" : "/engraving-list"
              )
            }, 500)
          }
        })
        .catch(() => {
          setLoading(false)
          toastr.error("Bekelnmedik bir hata oluştu lütfen iletişime geçiniz.")
        })
    } else {
      toastr.error("Lütfen kayıt edilecek tasarım yükleme işlemi yapınız.")
      setLoading(false)
      Sounds.warning()
    }
  }

  const deleteOriginalImages = values => {
    setDeleteBtn(true)
    setLoading(true)
    const obj = {
      orderId: state.id,
      positionId: values?.position?.value,
    }
    if (obj.positionId) {
      Http.post("api/orders/RemoveEngravingImage", obj)
        .then(res => {
          if (res.success) {
            toastr.success("Silme işlem başarılı")
            Sounds.success()
            getSkuImage()

            if (values.type === 10) {
              let newFrontData = frontData.filter(x => x.id !== values.id)
              setFrontData(newFrontData)
            } else if (values.type === 20) {
              let newBackData = backData.filter(x => x.id !== values.id)
              setBackData(newBackData)
            } else if (values.type === 30) {
              let newInteriorData = interriorData.filter(
                x => x.id !== values.id
              )
              setInterriorData(newInteriorData)
            } else if (values.type === 40) {
              let newSpineData = spineData.filter(x => x.id !== values.id)
              setSpineData(newSpineData)
            } else if (values.type === 50) {
              let newToolsData = toolsData.filter(x => x.id !== values.id)
              setToolsData(newToolsData)
            } else if (values.type === 60) {
              let newStonesData = stonesData.filter(x => x.id !== values.id)
              setStonesData(newStonesData)
            } else if (values.type === 70) {
              let newGuiterData = guitarData.filter(x => x.id !== values.id)
              setGuitarData(newGuiterData)
            }
            let newSaveData = saveData.filter(x => x.id !== values.id)
            setSaveData(newSaveData)
            setLoading(false)
          }
          setDeleteBtn(false)
        })
        .catch(function (error) {
          setDeleteBtn(false)
          setLoading(false)
        })
    } else {
      setLoading(false)
      toastr.warning("Position bilgisi bulunamadı lütfen iletişime geçiniz.")
      Sounds.warning()
    }
  }

  return (
    <React.Fragment>
      <style>{css}</style>
      <Spin spinning={loading}>
        <div className="page-content">
          <Breadcrumbs
            title={
              <>
                Engraving Design Upload Page{" "}
                <span style={{ fontSize: 14 }}>
                  <span style={{ opacity: "0.3" }}>[ Order: </span>
                  {state?.orderId}{" "}
                  <span style={{ opacity: "0.3" }}>| Sku: </span>
                  {state?.sku}
                  <span style={{ opacity: "0.3" }}> ]</span>
                </span>
              </>
            }
            button={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                  columnGap: 15,
                }}
              >
                <Button
                  type="button"
                  className="btn btn_default d-flex align-items-center justify-content-center"
                  onClick={() => {
                    navigate(
                      state?.waitingList
                        ? "/waiting-for-desing"
                        : "/engraving-list"
                    )
                  }}
                  icon={
                    <i className="bx bx-arrow-back font-size-16 align-middle mr-2"></i>
                  }
                >
                  Return to List
                </Button>
                {!state?.delete && (
                  <Button
                    type="button"
                    className="btn btn-primary d-flex align-items-center justify-content-center mt-1"
                    style={{ maxWidth: 100 }}
                    onClick={() => {
                      setLoading(true)
                      setTimeout(() => {
                        refForm
                          .validateFields()
                          .then(() => {
                            handleSave()
                          })
                          .catch(err => {
                            setLoading(false)
                          })
                      }, 300)
                    }}
                  >
                    {" "}
                    Design Save
                  </Button>
                )}
              </div>
            }
          />
          <Card className="mt-3">
            <style>{css}</style>
            <Form
              form={refForm}
              layout="vertical"
              onSubmit={e => {
                e.preventDefault()
                return false
              }}
            >
              <Row gutter={[16, 0]}>
                {frontLeatherData.length > 0 && (
                  <Col lg={8}>
                    <Card
                      className={"mb-3"}
                      hoverable
                      style={{ minHeight: 480 }}
                    >
                      {frontLeatherData.map((item, index) => {
                        return (
                          <div key={index}>
                            <style>
                              {`
                                .svg${index} .gs0 .st_0,  .svg${index} .gs0 .st0, .svg${index}  .gs1 .st_0,   .svg${index} g polygon,  .svg${index}  polyline, circle{
                                  fill:${item.colorHexCode}!important;
                                  stroke:black;
                                  stroke-width:25px!important;
                                }
                                g g .st1,
                                g g .st2,
                                g g .st3,
                                g g .st4,
                                g g .st5{
                                  fill:white!important;
                                  stroke:red;
                                  stroke-width:25px!important;
                                }
                                rect{
                                  fill:${item.colorHexCode}!important;
                                  stroke:${
                                    item.colorHexCode === "#111111"
                                      ? "red"
                                      : "red"
                                  };
                                  stroke-width:25px!important;
                                }
                                .svg${index}  polygon, polygon{
                                  fill:white!important;
                                  stroke:black;
                                  stroke-width:25px!important;
                               }
                                `}
                            </style>
                            {item.positionCodes.find(
                              x => x === "front_center"
                            ) && (
                              <Row
                                gutter={[16, 0]}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "start",
                                }}
                              >
                                {!state?.delete && (
                                  <Col lg={24}>
                                    <Form.Item
                                      name="front_center"
                                      label="Front Center Design"
                                    >
                                      <Upload
                                        maxCount={1}
                                        beforeUpload={file => {
                                          file.url = URL.createObjectURL(file)
                                          let find = leatherPosition.find(
                                            x => x.code === "front_center"
                                          )
                                          handleAcceptedFiles(file, item, find)
                                          return false
                                        }}
                                        fileList={[]}
                                        disabled={
                                          frontData.find(
                                            x =>
                                              x?.position?.code ===
                                              "front_center"
                                          ) &&
                                          frontData.find(
                                            x =>
                                              x?.position?.code ===
                                              "front_center"
                                          ).files.url
                                        }
                                      >
                                        <Button
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                          icon={<UploadOutlined />}
                                          disabled={
                                            frontData.find(
                                              x =>
                                                x?.position?.code ===
                                                "front_center"
                                            ) &&
                                            frontData.find(
                                              x =>
                                                x?.position?.code ===
                                                "front_center"
                                            ).files.url
                                          }
                                        >
                                          {frontData.find(
                                            x =>
                                              x?.position?.code ===
                                              "front_center"
                                          )
                                            ? frontData.find(
                                                x =>
                                                  x?.position?.code ===
                                                  "front_center"
                                              ).files.name
                                            : "Select Design"}
                                        </Button>
                                      </Upload>
                                    </Form.Item>
                                  </Col>
                                )}
                                {frontData.find(
                                  x => x?.position?.code === "front_center"
                                ) && (
                                  <Col lg={24}>
                                    <Descriptions
                                      title={
                                        state?.delete
                                          ? "Front Center Design"
                                          : ""
                                      }
                                      bordered
                                      layout="vertical"
                                      className="mb-3"
                                    >
                                      <Descriptions.Item label="Template">
                                        <SvgComponent
                                          svgUrl={item.imageUrl}
                                          className={"front svg" + index}
                                          width={100}
                                          height={100}
                                        />
                                      </Descriptions.Item>
                                      <Descriptions.Item label="Design">
                                        <Image
                                          width={100}
                                          src={
                                            frontData.find(
                                              x =>
                                                x?.position?.code ===
                                                "front_center"
                                            ).files.url
                                          }
                                        />
                                      </Descriptions.Item>
                                      {!state?.delete && (
                                        <Descriptions.Item label="Actions">
                                          {frontData.find(
                                            x =>
                                              x?.position?.code ===
                                              "front_center"
                                          )?.original ? (
                                            <Popconfirm
                                              title="Attention!"
                                              description={
                                                <>
                                                  Are you sure you want to
                                                  delete the saved design for
                                                  order number <br />
                                                  <span
                                                    style={{
                                                      fontWeight: "700",
                                                    }}
                                                  >
                                                    {state?.orderId}
                                                  </span>{" "}
                                                </>
                                              }
                                              onConfirm={() => {
                                                let find = frontData.find(
                                                  x =>
                                                    x?.position?.code ===
                                                    "front_center"
                                                )
                                                deleteOriginalImages(find)
                                              }}
                                              okText="Yes"
                                              cancelText="No"
                                            >
                                              <Button
                                                disabled={deleteBtn}
                                                type="button"
                                                className="btn btn-danger d-flex align-items-center justify-content-center"
                                                icon={<ClearOutlined />}
                                                style={{
                                                  margin: "15px auto 0 auto",
                                                }}
                                              >
                                                Delete{" "}
                                              </Button>
                                            </Popconfirm>
                                          ) : (
                                            <Button
                                              type="button"
                                              className="btn btn_tertiary d-flex align-items-center justify-content-center"
                                              icon={<ClearOutlined />}
                                              onClick={() => {
                                                let filter = frontData.filter(
                                                  x =>
                                                    x?.position?.code !==
                                                    "front_center"
                                                )
                                                setFrontData(filter)
                                              }}
                                              style={{
                                                margin: "15px auto 0 auto",
                                              }}
                                            >
                                              Delete{" "}
                                            </Button>
                                          )}
                                        </Descriptions.Item>
                                      )}
                                    </Descriptions>
                                  </Col>
                                )}
                              </Row>
                            )}
                            {item.positionCodes.find(
                              x => x === "front_bottom_center"
                            ) && (
                              <Row
                                gutter={[16, 0]}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "start",
                                }}
                              >
                                {!state?.delete && (
                                  <Col lg={24}>
                                    <Form.Item
                                      name="front_bottom_center"
                                      label="Front Bottom Center Design"
                                    >
                                      <Upload
                                        maxCount={1}
                                        beforeUpload={file => {
                                          file.url = URL.createObjectURL(file)
                                          let find = leatherPosition.find(
                                            x =>
                                              x.code === "front_bottom_center"
                                          )
                                          handleAcceptedFiles(file, item, find)
                                          return false
                                        }}
                                        fileList={[]}
                                        disabled={
                                          frontData.find(
                                            x =>
                                              x?.position?.code ===
                                              "front_bottom_center"
                                          ) &&
                                          frontData.find(
                                            x =>
                                              x?.position?.code ===
                                              "front_bottom_center"
                                          ).files.url
                                        }
                                      >
                                        <Button
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                          icon={<UploadOutlined />}
                                          disabled={
                                            frontData.find(
                                              x =>
                                                x?.position?.code ===
                                                "front_bottom_center"
                                            ) &&
                                            frontData.find(
                                              x =>
                                                x?.position?.code ===
                                                "front_bottom_center"
                                            ).files.url
                                          }
                                        >
                                          {frontData.find(
                                            x =>
                                              x?.position?.code ===
                                              "front_bottom_center"
                                          )
                                            ? frontData.find(
                                                x =>
                                                  x?.position?.code ===
                                                  "front_bottom_center"
                                              ).files.name
                                            : "Select Design"}
                                        </Button>
                                      </Upload>
                                    </Form.Item>
                                  </Col>
                                )}

                                {frontData.find(
                                  x =>
                                    x?.position?.code === "front_bottom_center"
                                ) && (
                                  <Col lg={24}>
                                    <Descriptions
                                      title={
                                        state?.delete
                                          ? "Front Bottom Center Design"
                                          : ""
                                      }
                                      bordered
                                      layout="vertical"
                                      className="mb-3"
                                    >
                                      <Descriptions.Item label="Template">
                                        <SvgComponent
                                          svgUrl={item.imageUrl}
                                          className={"svg" + index}
                                          width={100}
                                          height={100}
                                        />
                                      </Descriptions.Item>
                                      <Descriptions.Item label="Design">
                                        <Image
                                          width={100}
                                          src={
                                            frontData.find(
                                              x =>
                                                x?.position?.code ===
                                                "front_bottom_center"
                                            ).files.url
                                          }
                                        />
                                      </Descriptions.Item>
                                      {!state?.delete && (
                                        <Descriptions.Item label="Actions">
                                          {frontData.find(
                                            x =>
                                              x?.position?.code ===
                                              "front_bottom_center"
                                          )?.original ? (
                                            <Popconfirm
                                              title="Attention!"
                                              description={
                                                <>
                                                  Are you sure you want to
                                                  delete the saved design for
                                                  order number <br />
                                                  <span
                                                    style={{
                                                      fontWeight: "700",
                                                    }}
                                                  >
                                                    {state?.orderId}
                                                  </span>{" "}
                                                </>
                                              }
                                              onConfirm={() => {
                                                let find = frontData.find(
                                                  x =>
                                                    x?.position?.code ===
                                                    "front_bottom_center"
                                                )
                                                deleteOriginalImages(find)
                                              }}
                                              okText="Yes"
                                              cancelText="No"
                                            >
                                              <Button
                                                disabled={deleteBtn}
                                                type="button"
                                                className="btn btn-danger d-flex align-items-center justify-content-center"
                                                icon={<ClearOutlined />}
                                                style={{
                                                  margin: "15px auto 0 auto",
                                                }}
                                              >
                                                Delete{" "}
                                              </Button>
                                            </Popconfirm>
                                          ) : (
                                            <Button
                                              type="button"
                                              className="btn btn_tertiary d-flex align-items-center justify-content-center"
                                              icon={<ClearOutlined />}
                                              onClick={() => {
                                                let filter = frontData.filter(
                                                  x =>
                                                    x?.position?.code !==
                                                    "front_bottom_center"
                                                )
                                                setFrontData(filter)
                                              }}
                                              style={{
                                                margin: "15px auto 0 auto",
                                              }}
                                            >
                                              Delete{" "}
                                            </Button>
                                          )}
                                        </Descriptions.Item>
                                      )}
                                    </Descriptions>
                                  </Col>
                                )}
                              </Row>
                            )}
                            {item.positionCodes.find(
                              x => x === "front_vertical_on_the_right"
                            ) && (
                              <Row
                                gutter={[16, 0]}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "start",
                                }}
                              >
                                {!state?.delete && (
                                  <Col lg={24}>
                                    <Form.Item
                                      name="front_vertical_on_the_right"
                                      label="Front Vertical On The Right Design"
                                    >
                                      <Upload
                                        maxCount={1}
                                        beforeUpload={file => {
                                          file.url = URL.createObjectURL(file)
                                          let find = leatherPosition.find(
                                            x =>
                                              x.code ===
                                              "front_vertical_on_the_right"
                                          )
                                          handleAcceptedFiles(file, item, find)
                                          return false
                                        }}
                                        fileList={[]}
                                        disabled={
                                          frontData.find(
                                            x =>
                                              x?.position?.code ===
                                              "front_vertical_on_the_right"
                                          ) &&
                                          frontData.find(
                                            x =>
                                              x?.position?.code ===
                                              "front_vertical_on_the_right"
                                          ).files.url
                                        }
                                      >
                                        <Button
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                          icon={<UploadOutlined />}
                                          disabled={
                                            frontData.find(
                                              x =>
                                                x?.position?.code ===
                                                "front_vertical_on_the_right"
                                            ) &&
                                            frontData.find(
                                              x =>
                                                x?.position?.code ===
                                                "front_vertical_on_the_right"
                                            ).files.url
                                          }
                                        >
                                          {frontData.find(
                                            x =>
                                              x?.position?.code ===
                                              "front_vertical_on_the_right"
                                          )
                                            ? frontData.find(
                                                x =>
                                                  x?.position?.code ===
                                                  "front_vertical_on_the_right"
                                              ).files.name
                                            : "Select Design"}
                                        </Button>
                                      </Upload>
                                    </Form.Item>
                                  </Col>
                                )}

                                {frontData.find(
                                  x =>
                                    x?.position?.code ===
                                    "front_vertical_on_the_right"
                                ) && (
                                  <Col lg={24}>
                                    <Descriptions
                                      title={
                                        state?.delete
                                          ? "Front Certical on the Right Design"
                                          : ""
                                      }
                                      bordered
                                      layout="vertical"
                                      className="mb-3"
                                    >
                                      <Descriptions.Item label="Template">
                                        <SvgComponent
                                          svgUrl={item.imageUrl}
                                          className={"svg" + index}
                                          width={100}
                                          height={100}
                                        />
                                      </Descriptions.Item>
                                      <Descriptions.Item label="Design">
                                        <Image
                                          width={100}
                                          src={
                                            frontData.find(
                                              x =>
                                                x?.position?.code ===
                                                "front_vertical_on_the_right"
                                            ).files.url
                                          }
                                        />
                                      </Descriptions.Item>
                                      {!state?.delete && (
                                        <Descriptions.Item label="Actions">
                                          {frontData.find(
                                            x =>
                                              x?.position?.code ===
                                              "front_vertical_on_the_right"
                                          )?.original ? (
                                            <Popconfirm
                                              title="Attention!"
                                              description={
                                                <>
                                                  Are you sure you want to
                                                  delete the saved design for
                                                  order number <br />
                                                  <span
                                                    style={{
                                                      fontWeight: "700",
                                                    }}
                                                  >
                                                    {state?.orderId}
                                                  </span>{" "}
                                                </>
                                              }
                                              onConfirm={() => {
                                                let find = frontData.find(
                                                  x =>
                                                    x?.position?.code ===
                                                    "front_vertical_on_the_right"
                                                )
                                                deleteOriginalImages(find)
                                              }}
                                              okText="Yes"
                                              cancelText="No"
                                            >
                                              <Button
                                                type="button"
                                                disabled={deleteBtn}
                                                className="btn btn-danger d-flex align-items-center justify-content-center"
                                                icon={<ClearOutlined />}
                                                style={{
                                                  margin: "15px auto 0 auto",
                                                }}
                                              >
                                                Delete{" "}
                                              </Button>
                                            </Popconfirm>
                                          ) : (
                                            <Button
                                              type="button"
                                              className="btn btn-danger d-flex align-items-center justify-content-center"
                                              icon={<ClearOutlined />}
                                              onClick={() => {
                                                let filter = frontData.filter(
                                                  x =>
                                                    x?.position?.code !==
                                                    "front_vertical_on_the_right"
                                                )
                                                setFrontData(filter)
                                              }}
                                              style={{
                                                margin: "15px auto 0 auto",
                                              }}
                                            >
                                              Delete{" "}
                                            </Button>
                                          )}
                                        </Descriptions.Item>
                                      )}
                                    </Descriptions>
                                  </Col>
                                )}
                              </Row>
                            )}
                            {item.positionCodes.find(
                              x => x === "front_left_lower_corner"
                            ) && (
                              <Row
                                gutter={[16, 0]}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "start",
                                }}
                              >
                                {!state?.delete && (
                                  <Col lg={24}>
                                    <Form.Item
                                      name="front_left_lower_corner"
                                      label="Front Left Lower Corner Design"
                                    >
                                      <Upload
                                        maxCount={1}
                                        beforeUpload={file => {
                                          file.url = URL.createObjectURL(file)
                                          let find = leatherPosition.find(
                                            x =>
                                              x.code ===
                                              "front_left_lower_corner"
                                          )
                                          handleAcceptedFiles(file, item, find)
                                          return false
                                        }}
                                        fileList={[]}
                                        disabled={
                                          frontData.find(
                                            x =>
                                              x?.position?.code ===
                                              "front_left_lower_corner"
                                          ) &&
                                          frontData.find(
                                            x =>
                                              x?.position?.code ===
                                              "front_left_lower_corner"
                                          ).files.url
                                        }
                                      >
                                        <Button
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                          icon={<UploadOutlined />}
                                          disabled={
                                            frontData.find(
                                              x =>
                                                x?.position?.code ===
                                                "front_left_lower_corner"
                                            ) &&
                                            frontData.find(
                                              x =>
                                                x?.position?.code ===
                                                "front_left_lower_corner"
                                            ).files.url
                                          }
                                        >
                                          {frontData.find(
                                            x =>
                                              x?.position?.code ===
                                              "front_left_lower_corner"
                                          )
                                            ? frontData.find(
                                                x =>
                                                  x?.position?.code ===
                                                  "front_left_lower_corner"
                                              ).files.name
                                            : "Select Design"}
                                        </Button>
                                      </Upload>
                                    </Form.Item>
                                  </Col>
                                )}

                                {frontData.find(
                                  x =>
                                    x?.position?.code ===
                                    "front_left_lower_corner"
                                ) && (
                                  <Col lg={424}>
                                    <Descriptions
                                      title={
                                        state?.delete
                                          ? "Front Left Lower Corner Design"
                                          : ""
                                      }
                                      bordered
                                      layout="vertical"
                                      className="mb-3"
                                    >
                                      <Descriptions.Item label="Template">
                                        <SvgComponent
                                          svgUrl={item.imageUrl}
                                          className={"svg" + index}
                                          width={100}
                                          height={100}
                                        />
                                      </Descriptions.Item>
                                      <Descriptions.Item label="Design">
                                        <Image
                                          width={100}
                                          src={
                                            frontData.find(
                                              x =>
                                                x?.position?.code ===
                                                "front_left_lower_corner"
                                            ).files.url
                                          }
                                        />
                                      </Descriptions.Item>
                                      {!state?.delete && (
                                        <Descriptions.Item label="Actions">
                                          {frontData.find(
                                            x =>
                                              x?.position?.code ===
                                              "front_left_lower_corner"
                                          )?.original ? (
                                            <Popconfirm
                                              title="Attention!"
                                              description={
                                                <>
                                                  Are you sure you want to
                                                  delete the saved design for
                                                  order number <br />
                                                  <span
                                                    style={{
                                                      fontWeight: "700",
                                                    }}
                                                  >
                                                    {state?.orderId}
                                                  </span>{" "}
                                                </>
                                              }
                                              onConfirm={() => {
                                                let find = frontData.find(
                                                  x =>
                                                    x?.position?.code ===
                                                    "front_left_lower_corner"
                                                )
                                                deleteOriginalImages(find)
                                              }}
                                              okText="Yes"
                                              cancelText="No"
                                            >
                                              <Button
                                                disabled={deleteBtn}
                                                type="button"
                                                className={
                                                  "btn btn-danger d-flex align-items-center justify-content-center"
                                                }
                                                icon={<ClearOutlined />}
                                                style={{
                                                  margin: "15px auto 0 auto",
                                                }}
                                              >
                                                Delete{" "}
                                              </Button>
                                            </Popconfirm>
                                          ) : (
                                            <Button
                                              type="button"
                                              className={
                                                "btn btn_tertiary d-flex align-items-center justify-content-center"
                                              }
                                              icon={<ClearOutlined />}
                                              onClick={() => {
                                                let filter = frontData.filter(
                                                  x =>
                                                    x?.position?.code !==
                                                    "front_left_lower_corner"
                                                )
                                                setFrontData(filter)
                                              }}
                                              style={{
                                                margin: "15px auto 0 auto",
                                              }}
                                            >
                                              Delete{" "}
                                            </Button>
                                          )}
                                        </Descriptions.Item>
                                      )}
                                    </Descriptions>
                                  </Col>
                                )}
                              </Row>
                            )}
                            {item.positionCodes.find(
                              x => x === "front_right_lower_corner"
                            ) && (
                              <Row
                                gutter={[16, 0]}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "start",
                                }}
                              >
                                {!state?.delete && (
                                  <Col lg={24}>
                                    <Form.Item
                                      name="front_right_lower_corner"
                                      label="Front Right Lower Corner Design"
                                    >
                                      <Upload
                                        maxCount={1}
                                        beforeUpload={file => {
                                          file.url = URL.createObjectURL(file)
                                          let find = leatherPosition.find(
                                            x =>
                                              x.code ===
                                              "front_right_lower_corner"
                                          )
                                          handleAcceptedFiles(file, item, find)
                                          return false
                                        }}
                                        fileList={[]}
                                        disabled={
                                          frontData.find(
                                            x =>
                                              x?.position?.code ===
                                              "front_right_lower_corner"
                                          ) &&
                                          frontData.find(
                                            x =>
                                              x?.position?.code ===
                                              "front_right_lower_corner"
                                          ).files.url
                                        }
                                      >
                                        <Button
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                          icon={<UploadOutlined />}
                                          disabled={
                                            frontData.find(
                                              x =>
                                                x?.position?.code ===
                                                "front_right_lower_corner"
                                            ) &&
                                            frontData.find(
                                              x =>
                                                x?.position?.code ===
                                                "front_right_lower_corner"
                                            ).files.url
                                          }
                                        >
                                          {frontData.find(
                                            x =>
                                              x?.position?.code ===
                                              "front_right_lower_corner"
                                          )
                                            ? frontData.find(
                                                x =>
                                                  x?.position?.code ===
                                                  "front_right_lower_corner"
                                              ).files.name
                                            : "Select Design"}
                                        </Button>
                                      </Upload>
                                    </Form.Item>
                                  </Col>
                                )}

                                {frontData.find(
                                  x =>
                                    x?.position?.code ===
                                    "front_right_lower_corner"
                                ) && (
                                  <Col lg={24}>
                                    <Descriptions
                                      title={
                                        state?.delete
                                          ? "Front Right Lower Corner Design"
                                          : ""
                                      }
                                      bordered
                                      layout="vertical"
                                      className="mb-3"
                                    >
                                      <Descriptions.Item label="Template">
                                        <SvgComponent
                                          svgUrl={item.imageUrl}
                                          className={"svg" + index}
                                          width={100}
                                          height={100}
                                        />
                                      </Descriptions.Item>
                                      <Descriptions.Item label="Design">
                                        <Image
                                          width={100}
                                          src={
                                            frontData.find(
                                              x =>
                                                x?.position?.code ===
                                                "front_right_lower_corner"
                                            ).files.url
                                          }
                                        />
                                      </Descriptions.Item>
                                      {!state?.delete && (
                                        <Descriptions.Item label="Actions">
                                          {frontData.find(
                                            x =>
                                              x?.position?.code ===
                                              "front_right_lower_corner"
                                          )?.original ? (
                                            <Popconfirm
                                              title="Attention!"
                                              description={
                                                <>
                                                  Are you sure you want to
                                                  delete the saved design for
                                                  order number <br />
                                                  <span
                                                    style={{
                                                      fontWeight: "700",
                                                    }}
                                                  >
                                                    {state?.orderId}
                                                  </span>{" "}
                                                </>
                                              }
                                              onConfirm={() => {
                                                let find = frontData.find(
                                                  x =>
                                                    x?.position?.code ===
                                                    "front_right_lower_corner"
                                                )
                                                deleteOriginalImages(find)
                                              }}
                                              okText="Yes"
                                              cancelText="No"
                                            >
                                              <Button
                                                disabled={deleteBtn}
                                                type="button"
                                                className={
                                                  "btn btn-danger d-flex align-items-center justify-content-center"
                                                }
                                                icon={<ClearOutlined />}
                                                style={{
                                                  margin: "15px auto 0 auto",
                                                }}
                                              >
                                                Delete{" "}
                                              </Button>
                                            </Popconfirm>
                                          ) : (
                                            <Button
                                              type="button"
                                              className={
                                                "btn btn_tertiary d-flex align-items-center justify-content-center"
                                              }
                                              icon={<ClearOutlined />}
                                              onClick={() => {
                                                let filter = frontData.filter(
                                                  x =>
                                                    x?.position?.code !==
                                                    "front_right_lower_corner"
                                                )
                                                setFrontData(filter)
                                              }}
                                              style={{
                                                margin: "15px auto 0 auto",
                                              }}
                                            >
                                              Delete{" "}
                                            </Button>
                                          )}
                                        </Descriptions.Item>
                                      )}
                                    </Descriptions>
                                  </Col>
                                )}
                              </Row>
                            )}
                          </div>
                        )
                      })}
                    </Card>
                  </Col>
                )}
                {backLeatherData.length > 0 && (
                  <Col lg={8}>
                    <Card
                      className={"mb-3"}
                      hoverable
                      style={{ minHeight: 480 }}
                    >
                      {backLeatherData.map((item, index) => {
                        return (
                          <div key={index}>
                            <style>
                              {`
                                .svg${index} .gs0 .st_0,
                                .svg${index} .gs0 .st0,
                                .svg${index} .gs1 .st_0,
                                .svg${index} g polygon,
                                .svg${index} polyline,
                                circle{
                                  fill:${item.colorHexCode}!important;
                                  stroke:black;
                                  stroke-width:25px!important;
                                }
                                g g .st1,
                                g g .st2,
                                g g .st3,
                                g g .st4,
                                g g .st5{
                                  fill:white!important;
                                  stroke:red;
                                  stroke-width:25px!important;
                                }
                                .svg${index}  polygon, polygon{
                                  fill:white!important;
                                  stroke:black;
                                  stroke-width:25px!important;
                               }
                                `}
                            </style>

                            {item.positionCodes.find(
                              x => x === "back_center"
                            ) && (
                              <Row
                                gutter={[16, 0]}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "start",
                                }}
                              >
                                {!state?.delete && (
                                  <Col lg={24}>
                                    <Form.Item
                                      name="back_center"
                                      label="Back Center Design"
                                    >
                                      <Upload
                                        maxCount={1}
                                        beforeUpload={file => {
                                          file.url = URL.createObjectURL(file)
                                          let find = leatherPosition.find(
                                            x => x.code === "back_center"
                                          )
                                          handleAcceptedFiles(file, item, find)
                                          return false
                                        }}
                                        fileList={[]}
                                        disabled={
                                          backData.find(
                                            x =>
                                              x?.position?.code ===
                                              "back_center"
                                          ) &&
                                          backData.find(
                                            x =>
                                              x?.position?.code ===
                                              "back_center"
                                          ).files.url
                                        }
                                      >
                                        <Button
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                          icon={<UploadOutlined />}
                                          disabled={
                                            backData.find(
                                              x =>
                                                x?.position?.code ===
                                                "back_center"
                                            ) &&
                                            backData.find(
                                              x =>
                                                x?.position?.code ===
                                                "back_center"
                                            ).files.url
                                          }
                                        >
                                          {backData.find(
                                            x =>
                                              x?.position?.code ===
                                              "back_center"
                                          )
                                            ? backData.find(
                                                x =>
                                                  x?.position?.code ===
                                                  "back_center"
                                              ).files.name
                                            : "Select Design"}
                                        </Button>
                                      </Upload>
                                    </Form.Item>
                                  </Col>
                                )}

                                {backData.find(
                                  x => x?.position?.code === "back_center"
                                ) && (
                                  <Col lg={24}>
                                    <Descriptions
                                      title={
                                        state?.delete
                                          ? "Back Center Design"
                                          : ""
                                      }
                                      bordered
                                      layout="vertical"
                                      className="mb-3"
                                    >
                                      <Descriptions.Item label="Template">
                                        <SvgComponent
                                          svgUrl={item.imageUrl}
                                          className={"svg" + index}
                                          width={100}
                                          height={100}
                                        />
                                      </Descriptions.Item>
                                      <Descriptions.Item label="Design">
                                        <Image
                                          width={100}
                                          src={
                                            backData.find(
                                              x =>
                                                x?.position?.code ===
                                                "back_center"
                                            ).files.url
                                          }
                                        />
                                      </Descriptions.Item>
                                      {!state?.delete && (
                                        <Descriptions.Item label="Actions">
                                          {backData.find(
                                            x =>
                                              x?.position?.code ===
                                              "back_center"
                                          )?.original ? (
                                            <Popconfirm
                                              title="Attention!"
                                              description={
                                                <>
                                                  Are you sure you want to
                                                  delete the saved design for
                                                  order number <br />
                                                  <span
                                                    style={{
                                                      fontWeight: "700",
                                                    }}
                                                  >
                                                    {state?.orderId}
                                                  </span>{" "}
                                                </>
                                              }
                                              onConfirm={() => {
                                                let find = backData.find(
                                                  x =>
                                                    x?.position?.code ===
                                                    "back_center"
                                                )
                                                deleteOriginalImages(find)
                                              }}
                                              okText="Yes"
                                              cancelText="No"
                                            >
                                              <Button
                                                disabled={deleteBtn}
                                                type="button"
                                                className={
                                                  "btn btn-danger d-flex align-items-center justify-content-center"
                                                }
                                                icon={<ClearOutlined />}
                                                style={{
                                                  margin: "15px auto 0 auto",
                                                }}
                                              >
                                                Delete{" "}
                                              </Button>
                                            </Popconfirm>
                                          ) : (
                                            <Button
                                              type="button"
                                              className={
                                                "btn btn_tertiary d-flex align-items-center justify-content-center"
                                              }
                                              icon={<ClearOutlined />}
                                              onClick={() => {
                                                let filter = backData.filter(
                                                  x =>
                                                    x?.position?.code !==
                                                    "back_center"
                                                )
                                                setBackData(filter)
                                              }}
                                              style={{
                                                margin: "15px auto 0 auto",
                                              }}
                                            >
                                              Delete{" "}
                                            </Button>
                                          )}
                                        </Descriptions.Item>
                                      )}
                                    </Descriptions>
                                  </Col>
                                )}
                              </Row>
                            )}
                            {item.positionCodes.find(
                              x => x === "back_right_lower_corner"
                            ) && (
                              <Row
                                gutter={[16, 0]}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "start",
                                }}
                              >
                                {!state?.delete && (
                                  <Col lg={24}>
                                    <Form.Item
                                      name="back_right_lower_corner"
                                      label="Back Right Lower Corner Design"
                                    >
                                      <Upload
                                        maxCount={1}
                                        beforeUpload={file => {
                                          file.url = URL.createObjectURL(file)
                                          let find = leatherPosition.find(
                                            x =>
                                              x.code ===
                                              "back_right_lower_corner"
                                          )
                                          handleAcceptedFiles(file, item, find)
                                          return false
                                        }}
                                        fileList={[]}
                                        disabled={
                                          backData.find(
                                            x =>
                                              x?.position?.code ===
                                              "back_right_lower_corner"
                                          ) &&
                                          backData.find(
                                            x =>
                                              x?.position?.code ===
                                              "back_right_lower_corner"
                                          ).files.url
                                        }
                                      >
                                        <Button
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                          icon={<UploadOutlined />}
                                          disabled={
                                            backData.find(
                                              x =>
                                                x?.position?.code ===
                                                "back_right_lower_corner"
                                            ) &&
                                            backData.find(
                                              x =>
                                                x?.position?.code ===
                                                "back_right_lower_corner"
                                            ).files.url
                                          }
                                        >
                                          {backData.find(
                                            x =>
                                              x?.position?.code ===
                                              "back_right_lower_corner"
                                          )
                                            ? backData.find(
                                                x =>
                                                  x?.position?.code ===
                                                  "back_right_lower_corner"
                                              ).files.name
                                            : "Select Design"}
                                        </Button>
                                      </Upload>
                                    </Form.Item>
                                  </Col>
                                )}

                                {backData.find(
                                  x =>
                                    x?.position?.code ===
                                    "back_right_lower_corner"
                                ) && (
                                  <Col lg={24}>
                                    <Descriptions
                                      title={
                                        state?.delete
                                          ? "Back Right Lower Corner Design"
                                          : ""
                                      }
                                      bordered
                                      layout="vertical"
                                      className="mb-3"
                                    >
                                      <Descriptions.Item label="Template">
                                        <SvgComponent
                                          svgUrl={item.imageUrl}
                                          className={"svg" + index}
                                          width={100}
                                          height={100}
                                        />
                                      </Descriptions.Item>
                                      <Descriptions.Item label="Design">
                                        <Image
                                          width={100}
                                          src={
                                            backData.find(
                                              x =>
                                                x?.position?.code ===
                                                "back_right_lower_corner"
                                            ).files.url
                                          }
                                        />
                                      </Descriptions.Item>
                                      {!state?.delete && (
                                        <Descriptions.Item label="Actions">
                                          {backData.find(
                                            x =>
                                              x?.position?.code ===
                                              "back_right_lower_corner"
                                          )?.original ? (
                                            <Popconfirm
                                              title="Attention!"
                                              description={
                                                <>
                                                  Are you sure you want to
                                                  delete the saved design for
                                                  order number <br />
                                                  <span
                                                    style={{
                                                      fontWeight: "700",
                                                    }}
                                                  >
                                                    {state?.orderId}
                                                  </span>{" "}
                                                </>
                                              }
                                              onConfirm={() => {
                                                let find = backData.find(
                                                  x =>
                                                    x?.position?.code ===
                                                    "back_right_lower_corner"
                                                )
                                                deleteOriginalImages(find)
                                              }}
                                              okText="Yes"
                                              cancelText="No"
                                            >
                                              <Button
                                                disabled={deleteBtn}
                                                type="button"
                                                className={
                                                  "btn btn-danger d-flex align-items-center justify-content-center"
                                                }
                                                icon={<ClearOutlined />}
                                                style={{
                                                  margin: "15px auto 0 auto",
                                                }}
                                              >
                                                Delete{" "}
                                              </Button>
                                            </Popconfirm>
                                          ) : (
                                            <Button
                                              type="button"
                                              className={
                                                "btn btn_tertiary d-flex align-items-center justify-content-center"
                                              }
                                              icon={<ClearOutlined />}
                                              onClick={() => {
                                                let filter = backData.filter(
                                                  x =>
                                                    x?.position?.code !==
                                                    "back_right_lower_corner"
                                                )
                                                setBackData(filter)
                                              }}
                                              style={{
                                                margin: "15px auto 0 auto",
                                              }}
                                            >
                                              Delete{" "}
                                            </Button>
                                          )}
                                        </Descriptions.Item>
                                      )}
                                    </Descriptions>
                                  </Col>
                                )}
                              </Row>
                            )}
                            {item.positionCodes.find(
                              x => x === "back_bottom_center"
                            ) && (
                              <Row
                                gutter={[16, 0]}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "start",
                                }}
                              >
                                {!state?.delete && (
                                  <Col lg={24}>
                                    <Form.Item
                                      name="back_bottom_center"
                                      label="Back Bottom Center Design"
                                    >
                                      <Upload
                                        maxCount={1}
                                        beforeUpload={file => {
                                          file.url = URL.createObjectURL(file)
                                          let find = leatherPosition.find(
                                            x => x.code === "back_bottom_center"
                                          )
                                          handleAcceptedFiles(file, item, find)
                                          return false
                                        }}
                                        fileList={[]}
                                        disabled={
                                          backData.find(
                                            x =>
                                              x?.position?.code ===
                                              "back_bottom_center"
                                          ) &&
                                          backData.find(
                                            x =>
                                              x?.position?.code ===
                                              "back_bottom_center"
                                          ).files.url
                                        }
                                      >
                                        <Button
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                          icon={<UploadOutlined />}
                                          disabled={
                                            backData.find(
                                              x =>
                                                x?.position?.code ===
                                                "back_bottom_center"
                                            ) &&
                                            backData.find(
                                              x =>
                                                x?.position?.code ===
                                                "back_bottom_center"
                                            ).files.url
                                          }
                                        >
                                          {backData.find(
                                            x =>
                                              x?.position?.code ===
                                              "back_bottom_center"
                                          )
                                            ? backData.find(
                                                x =>
                                                  x?.position?.code ===
                                                  "back_bottom_center"
                                              ).files.name
                                            : "Select Design"}
                                        </Button>
                                      </Upload>
                                    </Form.Item>
                                  </Col>
                                )}

                                {backData.find(
                                  x =>
                                    x?.position?.code === "back_bottom_center"
                                ) && (
                                  <Col lg={24}>
                                    <Descriptions
                                      title={
                                        state?.delete
                                          ? "Back Bottom Design"
                                          : ""
                                      }
                                      bordered
                                      layout="vertical"
                                      className="mb-3"
                                    >
                                      <Descriptions.Item label="Template">
                                        <SvgComponent
                                          svgUrl={item.imageUrl}
                                          className={"svg" + index}
                                          width={100}
                                          height={100}
                                        />
                                      </Descriptions.Item>
                                      <Descriptions.Item label="Design">
                                        <Image
                                          width={100}
                                          src={
                                            backData.find(
                                              x =>
                                                x?.position?.code ===
                                                "back_bottom_center"
                                            ).files.url
                                          }
                                        />
                                      </Descriptions.Item>
                                      {!state?.delete && (
                                        <Descriptions.Item label="Actions">
                                          {backData.find(
                                            x =>
                                              x?.position?.code ===
                                              "back_bottom_center"
                                          )?.original ? (
                                            <Popconfirm
                                              title="Attention!"
                                              description={
                                                <>
                                                  Are you sure you want to
                                                  delete the saved design for
                                                  order number <br />
                                                  <span
                                                    style={{
                                                      fontWeight: "700",
                                                    }}
                                                  >
                                                    {state?.orderId}
                                                  </span>{" "}
                                                </>
                                              }
                                              onConfirm={() => {
                                                let find = backData.find(
                                                  x =>
                                                    x?.position?.code ===
                                                    "back_bottom_center"
                                                )
                                                deleteOriginalImages(find)
                                              }}
                                              okText="Yes"
                                              cancelText="No"
                                            >
                                              <Button
                                                disabled={deleteBtn}
                                                type="button"
                                                className={
                                                  "btn btn-danger d-flex align-items-center justify-content-center"
                                                }
                                                icon={<ClearOutlined />}
                                                style={{
                                                  margin: "15px auto 0 auto",
                                                }}
                                              >
                                                Delete{" "}
                                              </Button>
                                            </Popconfirm>
                                          ) : (
                                            <Button
                                              type="button"
                                              className={
                                                "btn btn_tertiary d-flex align-items-center justify-content-center"
                                              }
                                              icon={<ClearOutlined />}
                                              onClick={() => {
                                                let filter = backData.filter(
                                                  x =>
                                                    x?.position?.code !==
                                                    "back_bottom_center"
                                                )
                                                setBackData(filter)
                                              }}
                                              style={{
                                                margin: "15px auto 0 auto",
                                              }}
                                            >
                                              Delete{" "}
                                            </Button>
                                          )}
                                        </Descriptions.Item>
                                      )}
                                    </Descriptions>
                                  </Col>
                                )}
                              </Row>
                            )}
                          </div>
                        )
                      })}
                    </Card>
                  </Col>
                )}
                {insideLeatherData.length > 0 && (
                  <Col lg={8}>
                    <Card
                      className={"mb-3"}
                      hoverable
                      style={{ minHeight: 480 }}
                    >
                      {insideLeatherData.map((item, index) => {
                        return (
                          <div key={index}>
                            <style>
                              {`
                               .svg${index} path:first-child{
                                  fill:${item.colorHexCode}!important;
                               }
                              .svg${index} .gs0 .st_0,
                              .svg${index} .gs0 .st0,
                              .svg${index} .gs1 .st_0,
                              .svg${index} g polygon,
                              .svg${index} polyline,
                              circle{
                                  fill:${item.colorHexCode}!important;
                                  stroke:black;
                                  stroke-width:25px!important;
                                }
                                rect{
                                  fill:${item.colorHexCode}!important;
                                  stroke:${
                                    item.colorHexCode === "#111111"
                                      ? "red"
                                      : "red"
                                  };
                                  stroke-width:25px!important;
                                }
                                g g .st1,
                                g g .st2,
                                g g .st3,
                                g g .st4,
                                g g .st5{
                                  fill:white!important;
                                  stroke:red;
                                  stroke-width:25px!important;
                                }
                                .svg${index}  polygon, polygon{
                                  fill:white!important;
                                  stroke:black;
                                  stroke-width:25px!important;
                               }
                                `}
                            </style>

                            {item.positionCodes.find(
                              x => x === "inside_center"
                            ) && (
                              <Row
                                gutter={[16, 0]}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "start",
                                }}
                              >
                                {!state?.delete && (
                                  <Col lg={24}>
                                    <Form.Item
                                      name="inside_center"
                                      label="Inside Center Design"
                                    >
                                      <Upload
                                        maxCount={1}
                                        beforeUpload={file => {
                                          file.url = URL.createObjectURL(file)
                                          let find = leatherPosition.find(
                                            x => x.code === "inside_center"
                                          )
                                          handleAcceptedFiles(file, item, find)
                                          return false
                                        }}
                                        fileList={[]}
                                        disabled={
                                          interriorData.find(
                                            x =>
                                              x?.position?.code ===
                                              "inside_center"
                                          ) &&
                                          interriorData.find(
                                            x =>
                                              x?.position?.code ===
                                              "inside_center"
                                          ).files.url
                                        }
                                      >
                                        <Button
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                          icon={
                                            interriorData.find(
                                              x =>
                                                x?.position?.code ===
                                                "inside_center"
                                            ) ? null : (
                                              <UploadOutlined />
                                            )
                                          }
                                          disabled={
                                            interriorData.find(
                                              x =>
                                                x?.position?.code ===
                                                "inside_center"
                                            ) &&
                                            interriorData.find(
                                              x =>
                                                x?.position?.code ===
                                                "inside_center"
                                            ).files.url
                                          }
                                        >
                                          {interriorData.find(
                                            x =>
                                              x?.position?.code ===
                                              "inside_center"
                                          )
                                            ? interriorData.find(
                                                x =>
                                                  x?.position?.code ===
                                                  "inside_center"
                                              ).files.name
                                            : "Select Design"}
                                        </Button>
                                      </Upload>
                                    </Form.Item>
                                  </Col>
                                )}

                                {interriorData.find(
                                  x => x?.position?.code === "inside_center"
                                ) && (
                                  <Col lg={24}>
                                    <Descriptions
                                      title={
                                        state?.delete
                                          ? "Inside Center Design"
                                          : ""
                                      }
                                      bordered
                                      layout="vertical"
                                      className="mb-3"
                                    >
                                      <Descriptions.Item label="Template">
                                        <SvgComponent
                                          svgUrl={item.imageUrl}
                                          className={"inside svg" + index}
                                          width={100}
                                          height={100}
                                        />
                                      </Descriptions.Item>
                                      <Descriptions.Item label="Design">
                                        <Image
                                          width={100}
                                          src={
                                            interriorData.find(
                                              x =>
                                                x?.position?.code ===
                                                "inside_center"
                                            ).files.url
                                          }
                                        />
                                      </Descriptions.Item>
                                      {!state?.delete && (
                                        <Descriptions.Item label="Actions">
                                          {interriorData.find(
                                            x =>
                                              x?.position?.code ===
                                              "inside_center"
                                          )?.original ? (
                                            <Popconfirm
                                              title="Attention!"
                                              description={
                                                <>
                                                  Are you sure you want to
                                                  delete the saved design for
                                                  order number <br />
                                                  <span
                                                    style={{
                                                      fontWeight: "700",
                                                    }}
                                                  >
                                                    {state?.orderId}
                                                  </span>{" "}
                                                </>
                                              }
                                              onConfirm={() => {
                                                let find = interriorData.find(
                                                  x =>
                                                    x?.position?.code ===
                                                    "inside_center"
                                                )
                                                deleteOriginalImages(find)
                                              }}
                                              okText="Yes"
                                              cancelText="No"
                                            >
                                              <Button
                                                disabled={deleteBtn}
                                                type="button"
                                                className={
                                                  "btn btn-danger d-flex align-items-center justify-content-center"
                                                }
                                                icon={<ClearOutlined />}
                                                style={{
                                                  margin: "15px auto 0 auto",
                                                }}
                                              >
                                                Delete{" "}
                                              </Button>
                                            </Popconfirm>
                                          ) : (
                                            <Button
                                              type="button"
                                              className={
                                                "btn btn_tertiary d-flex align-items-center justify-content-center"
                                              }
                                              icon={<ClearOutlined />}
                                              onClick={() => {
                                                let filter = backData.filter(
                                                  x =>
                                                    x?.position?.code !==
                                                    "inside_center"
                                                )
                                                setInterriorData(filter)
                                              }}
                                              style={{
                                                margin: "15px auto 0 auto",
                                              }}
                                            >
                                              Delete{" "}
                                            </Button>
                                          )}
                                        </Descriptions.Item>
                                      )}
                                    </Descriptions>
                                  </Col>
                                )}
                              </Row>
                            )}
                            {item.positionCodes.find(
                              x => x === "inside_upper_side"
                            ) && (
                              <Row
                                gutter={[16, 0]}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "start",
                                }}
                              >
                                {!state?.delete && (
                                  <Col lg={24}>
                                    <Form.Item
                                      name="inside_upper_side"
                                      label="Inside Upper Side Design"
                                    >
                                      <Upload
                                        maxCount={1}
                                        beforeUpload={file => {
                                          file.url = URL.createObjectURL(file)
                                          let find = leatherPosition.find(
                                            x => x.code === "inside_upper_side"
                                          )
                                          handleAcceptedFiles(file, item, find)
                                          return false
                                        }}
                                        fileList={[]}
                                        disabled={
                                          interriorData.find(
                                            x =>
                                              x?.position?.code ===
                                              "inside_upper_side"
                                          ) &&
                                          interriorData.find(
                                            x =>
                                              x?.position?.code ===
                                              "inside_upper_side"
                                          ).files.url
                                        }
                                      >
                                        <Button
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                          icon={
                                            interriorData.find(
                                              x =>
                                                x?.position?.code ===
                                                "inside_upper_side"
                                            ) ? null : (
                                              <UploadOutlined />
                                            )
                                          }
                                          disabled={
                                            interriorData.find(
                                              x =>
                                                x?.position?.code ===
                                                "inside_upper_side"
                                            ) &&
                                            interriorData.find(
                                              x =>
                                                x?.position?.code ===
                                                "inside_upper_side"
                                            ).files.url
                                          }
                                        >
                                          {interriorData.find(
                                            x =>
                                              x?.position?.code ===
                                              "inside_upper_side"
                                          )
                                            ? interriorData.find(
                                                x =>
                                                  x?.position?.code ===
                                                  "inside_upper_side"
                                              ).files.name
                                            : "Select Design"}
                                        </Button>
                                      </Upload>
                                    </Form.Item>
                                  </Col>
                                )}

                                {interriorData.find(
                                  x => x?.position?.code === "inside_upper_side"
                                ) && (
                                  <Col lg={24}>
                                    <Descriptions
                                      title={
                                        state?.delete
                                          ? "Inside Upper Side Design"
                                          : ""
                                      }
                                      bordered
                                      layout="vertical"
                                      className="mb-3"
                                    >
                                      <Descriptions.Item label="Template">
                                        <SvgComponent
                                          svgUrl={item.imageUrl}
                                          className={"svg" + index}
                                          width={100}
                                          height={100}
                                        />
                                      </Descriptions.Item>
                                      <Descriptions.Item label="Design">
                                        <Image
                                          width={100}
                                          src={
                                            interriorData.find(
                                              x =>
                                                x?.position?.code ===
                                                "inside_upper_side"
                                            ).files.url
                                          }
                                        />
                                      </Descriptions.Item>
                                      {!state?.delete && (
                                        <Descriptions.Item label="Actions">
                                          {interriorData.find(
                                            x =>
                                              x?.position?.code ===
                                              "inside_upper_side"
                                          )?.original ? (
                                            <Popconfirm
                                              title="Attention!"
                                              description={
                                                <>
                                                  Are you sure you want to
                                                  delete the saved design for
                                                  order number <br />
                                                  <span
                                                    style={{
                                                      fontWeight: "700",
                                                    }}
                                                  >
                                                    {state?.orderId}
                                                  </span>{" "}
                                                </>
                                              }
                                              onConfirm={() => {
                                                let find = interriorData.find(
                                                  x =>
                                                    x?.position?.code ===
                                                    "inside_upper_side"
                                                )
                                                deleteOriginalImages(find)
                                              }}
                                              okText="Yes"
                                              cancelText="No"
                                            >
                                              <Button
                                                disabled={deleteBtn}
                                                type="button"
                                                className={
                                                  "btn btn-danger d-flex align-items-center justify-content-center"
                                                }
                                                icon={<ClearOutlined />}
                                                style={{
                                                  margin: "15px auto 0 auto",
                                                }}
                                              >
                                                Delete{" "}
                                              </Button>
                                            </Popconfirm>
                                          ) : (
                                            <Button
                                              type="button"
                                              className={
                                                "btn btn_tertiary d-flex align-items-center justify-content-center"
                                              }
                                              icon={<ClearOutlined />}
                                              onClick={() => {
                                                let filter = backData.filter(
                                                  x =>
                                                    x?.position?.code !==
                                                    "inside_upper_side"
                                                )
                                                setInterriorData(filter)
                                              }}
                                              style={{
                                                margin: "15px auto 0 auto",
                                              }}
                                            >
                                              Delete{" "}
                                            </Button>
                                          )}
                                        </Descriptions.Item>
                                      )}
                                    </Descriptions>
                                  </Col>
                                )}
                              </Row>
                            )}
                            {item.positionCodes.find(
                              x => x === "inside_left_lower_corner"
                            ) && (
                              <Row
                                gutter={[16, 0]}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "start",
                                }}
                              >
                                {!state?.delete && (
                                  <Col lg={24}>
                                    <Form.Item
                                      name="inside_left_lower_corner"
                                      label="Inside Left Lower Corner Design"
                                    >
                                      <Upload
                                        maxCount={1}
                                        beforeUpload={file => {
                                          file.url = URL.createObjectURL(file)
                                          let find = leatherPosition.find(
                                            x =>
                                              x.code ===
                                              "inside_left_lower_corner"
                                          )
                                          handleAcceptedFiles(file, item, find)
                                          return false
                                        }}
                                        fileList={[]}
                                        disabled={
                                          interriorData.find(
                                            x =>
                                              x?.position?.code ===
                                              "inside_left_lower_corner"
                                          ) &&
                                          interriorData.find(
                                            x =>
                                              x?.position?.code ===
                                              "inside_left_lower_corner"
                                          ).files.url
                                        }
                                      >
                                        <Button
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                          icon={
                                            interriorData.find(
                                              x =>
                                                x?.position?.code ===
                                                "inside_left_lower_corner"
                                            ) ? null : (
                                              <UploadOutlined />
                                            )
                                          }
                                          disabled={
                                            interriorData.find(
                                              x =>
                                                x?.position?.code ===
                                                "inside_left_lower_corner"
                                            ) &&
                                            interriorData.find(
                                              x =>
                                                x?.position?.code ===
                                                "inside_left_lower_corner"
                                            ).files.url
                                          }
                                        >
                                          {interriorData.find(
                                            x =>
                                              x?.position?.code ===
                                              "inside_left_lower_corner"
                                          )
                                            ? interriorData.find(
                                                x =>
                                                  x?.position?.code ===
                                                  "inside_left_lower_corner"
                                              ).files.name
                                            : "Select Design"}
                                        </Button>
                                      </Upload>
                                    </Form.Item>
                                  </Col>
                                )}

                                {interriorData.find(
                                  x =>
                                    x?.position?.code ===
                                    "inside_left_lower_corner"
                                ) && (
                                  <Col lg={24}>
                                    <Descriptions
                                      title={
                                        state?.delete
                                          ? "Inside Left Lower Corner Design"
                                          : ""
                                      }
                                      bordered
                                      layout="vertical"
                                      className="mb-3"
                                    >
                                      <Descriptions.Item label="Template">
                                        <SvgComponent
                                          svgUrl={item.imageUrl}
                                          className={"svg" + index}
                                          width={100}
                                          height={100}
                                        />
                                      </Descriptions.Item>
                                      <Descriptions.Item label="Design">
                                        <Image
                                          width={100}
                                          src={
                                            interriorData.find(
                                              x =>
                                                x?.position?.code ===
                                                "inside_left_lower_corner"
                                            ).files.url
                                          }
                                        />
                                      </Descriptions.Item>
                                      {!state?.delete && (
                                        <Descriptions.Item label="Actions">
                                          {interriorData.find(
                                            x =>
                                              x?.position?.code ===
                                              "inside_left_lower_corner"
                                          )?.original ? (
                                            <Popconfirm
                                              title="Attention!"
                                              description={
                                                <>
                                                  Are you sure you want to
                                                  delete the saved design for
                                                  order number <br />
                                                  <span
                                                    style={{
                                                      fontWeight: "700",
                                                    }}
                                                  >
                                                    {state?.orderId}
                                                  </span>{" "}
                                                </>
                                              }
                                              onConfirm={() => {
                                                let find = interriorData.find(
                                                  x =>
                                                    x?.position?.code ===
                                                    "inside_left_lower_corner"
                                                )
                                                deleteOriginalImages(find)
                                              }}
                                              okText="Yes"
                                              cancelText="No"
                                            >
                                              <Button
                                                disabled={deleteBtn}
                                                type="button"
                                                className={
                                                  "btn btn-danger d-flex align-items-center justify-content-center"
                                                }
                                                icon={<ClearOutlined />}
                                                style={{
                                                  margin: "15px auto 0 auto",
                                                }}
                                              >
                                                Delete{" "}
                                              </Button>
                                            </Popconfirm>
                                          ) : (
                                            <Button
                                              type="button"
                                              className={
                                                "btn btn_tertiary d-flex align-items-center justify-content-center"
                                              }
                                              icon={<ClearOutlined />}
                                              onClick={() => {
                                                let filter = backData.filter(
                                                  x =>
                                                    x?.position?.code !==
                                                    "inside_left_lower_corner"
                                                )
                                                setInterriorData(filter)
                                              }}
                                              style={{
                                                margin: "15px auto 0 auto",
                                              }}
                                            >
                                              Delete{" "}
                                            </Button>
                                          )}
                                        </Descriptions.Item>
                                      )}
                                    </Descriptions>
                                  </Col>
                                )}
                              </Row>
                            )}
                            {item.positionCodes.find(
                              x => x === "inside_left_side"
                            ) && (
                              <Row
                                gutter={[16, 0]}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "start",
                                }}
                              >
                                {!state?.delete && (
                                  <Col lg={24}>
                                    <Form.Item
                                      name="inside_left_side"
                                      label="Inside Left Side Design"
                                    >
                                      <Upload
                                        maxCount={1}
                                        onRemove={file => {
                                          // const index = fileList.indexOf(file)
                                          // const newFileList = fileList.slice()
                                          // newFileList.splice(index, 1)
                                          // setFileList(newFileList)
                                        }}
                                        beforeUpload={file => {
                                          file.url = URL.createObjectURL(file)
                                          let find = leatherPosition.find(
                                            x => x.code === "inside_left_side"
                                          )
                                          handleAcceptedFiles(file, item, find)
                                          return false
                                        }}
                                        fileList={[]}
                                        disabled={
                                          interriorData.find(
                                            x =>
                                              x.position?.code ===
                                              "inside_left_side"
                                          ) &&
                                          interriorData.find(
                                            x =>
                                              x.position?.code ===
                                              "inside_left_side"
                                          ).files.url
                                        }
                                      >
                                        <Button
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                          icon={
                                            interriorData.find(
                                              x =>
                                                x?.position?.code ===
                                                "inside_left_side"
                                            ) ? null : (
                                              <UploadOutlined />
                                            )
                                          }
                                          disabled={
                                            interriorData.find(
                                              x =>
                                                x?.position?.code ===
                                                "inside_left_side"
                                            ) &&
                                            interriorData.find(
                                              x =>
                                                x?.position?.code ===
                                                "inside_left_side"
                                            ).files.url
                                          }
                                        >
                                          {interriorData.find(
                                            x =>
                                              x?.position?.code ===
                                              "inside_left_side"
                                          )
                                            ? interriorData.find(
                                                x =>
                                                  x?.position?.code ===
                                                  "inside_left_side"
                                              ).files.name
                                            : "Select Design"}
                                        </Button>
                                      </Upload>
                                    </Form.Item>
                                  </Col>
                                )}

                                {interriorData.find(
                                  x => x?.position?.code === "inside_left_side"
                                ) && (
                                  <Col lg={24}>
                                    <Descriptions
                                      title={
                                        state?.delete
                                          ? "Inside Left Side Design"
                                          : ""
                                      }
                                      bordered
                                      layout="vertical"
                                      className="mb-3"
                                    >
                                      <Descriptions.Item label="Template">
                                        <SvgComponent
                                          svgUrl={item.imageUrl}
                                          className={"svg" + index}
                                          width={100}
                                          height={100}
                                        />
                                      </Descriptions.Item>
                                      <Descriptions.Item label="Design">
                                        <Image
                                          width={100}
                                          src={
                                            interriorData.find(
                                              x =>
                                                x?.position?.code ===
                                                "inside_left_side"
                                            ).files.url
                                          }
                                        />
                                      </Descriptions.Item>
                                      {!state?.delete && (
                                        <Descriptions.Item label="Actions">
                                          {interriorData.find(
                                            x =>
                                              x?.position?.code ===
                                              "inside_left_side"
                                          )?.original ? (
                                            <Popconfirm
                                              title="Attention!"
                                              description={
                                                <>
                                                  Are you sure you want to
                                                  delete the saved design for
                                                  order number <br />
                                                  <span
                                                    style={{
                                                      fontWeight: "700",
                                                    }}
                                                  >
                                                    {state?.orderId}
                                                  </span>{" "}
                                                </>
                                              }
                                              onConfirm={() => {
                                                let find = interriorData.find(
                                                  x =>
                                                    x?.position?.code ===
                                                    "inside_left_side"
                                                )
                                                deleteOriginalImages(find)
                                              }}
                                              okText="Yes"
                                              cancelText="No"
                                            >
                                              <Button
                                                disabled={deleteBtn}
                                                type="button"
                                                className={
                                                  "btn btn-danger d-flex align-items-center justify-content-center"
                                                }
                                                icon={<ClearOutlined />}
                                                style={{
                                                  margin: "15px auto 0 auto",
                                                }}
                                              >
                                                Delete{" "}
                                              </Button>
                                            </Popconfirm>
                                          ) : (
                                            <Button
                                              type="button"
                                              disabled={deleteBtn}
                                              className={
                                                "btn btn_tertiary d-flex align-items-center justify-content-center"
                                              }
                                              icon={<ClearOutlined />}
                                              onClick={() => {
                                                let filter = backData.filter(
                                                  x =>
                                                    x?.position?.code !==
                                                    "inside_left_side"
                                                )
                                                setInterriorData(filter)
                                              }}
                                              style={{
                                                margin: "15px auto 0 auto",
                                              }}
                                            >
                                              Delete{" "}
                                            </Button>
                                          )}
                                        </Descriptions.Item>
                                      )}
                                    </Descriptions>
                                  </Col>
                                )}
                              </Row>
                            )}
                            {item.positionCodes.find(
                              x => x === "inside_right_side"
                            ) && (
                              <Row
                                gutter={[16, 0]}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "start",
                                }}
                              >
                                {!state?.delete && (
                                  <Col lg={24}>
                                    <Form.Item
                                      name="inside_right_side"
                                      label="Inside Right Side Design"
                                    >
                                      <Upload
                                        maxCount={1}
                                        onRemove={file => {
                                          // const index = fileList.indexOf(file)
                                          // const newFileList = fileList.slice()
                                          // newFileList.splice(index, 1)
                                          // setFileList(newFileList)
                                        }}
                                        beforeUpload={file => {
                                          file.url = URL.createObjectURL(file)
                                          let find = leatherPosition.find(
                                            x => x.code === "inside_right_side"
                                          )
                                          handleAcceptedFiles(file, item, find)
                                          return false
                                        }}
                                        fileList={[]}
                                        disabled={
                                          interriorData.find(
                                            x =>
                                              x?.position?.code ===
                                              "inside_right_side"
                                          ) &&
                                          interriorData.find(
                                            x =>
                                              x?.position?.code ===
                                              "inside_right_side"
                                          ).files.url
                                        }
                                      >
                                        <Button
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                          icon={
                                            interriorData.find(
                                              x =>
                                                x?.position?.code ===
                                                "inside_right_side"
                                            ) ? null : (
                                              <UploadOutlined />
                                            )
                                          }
                                          disabled={
                                            interriorData.find(
                                              x =>
                                                x?.position?.code ===
                                                "inside_right_side"
                                            ) &&
                                            interriorData.find(
                                              x =>
                                                x?.position?.code ===
                                                "inside_right_side"
                                            ).files.url
                                          }
                                        >
                                          {interriorData.find(
                                            x =>
                                              x?.position?.code ===
                                              "inside_right_side"
                                          )
                                            ? interriorData.find(
                                                x =>
                                                  x?.position?.code ===
                                                  "inside_right_side"
                                              ).files.name
                                            : "Select Design"}
                                        </Button>
                                      </Upload>
                                    </Form.Item>
                                  </Col>
                                )}
                                {interriorData.find(
                                  x => x?.position?.code === "inside_right_side"
                                ) && (
                                  <Col lg={24}>
                                    <Descriptions
                                      title={
                                        state?.delete
                                          ? "Inside Right Side Design"
                                          : ""
                                      }
                                      bordered
                                      layout="vertical"
                                      className="mb-3"
                                    >
                                      <Descriptions.Item label="Template">
                                        <SvgComponent
                                          svgUrl={item.imageUrl}
                                          className={"svg" + index}
                                          width={100}
                                          height={100}
                                        />
                                      </Descriptions.Item>
                                      <Descriptions.Item label="Design">
                                        <Image
                                          width={100}
                                          src={
                                            interriorData.find(
                                              x =>
                                                x?.position?.code ===
                                                "inside_right_side"
                                            ).files.url
                                          }
                                        />
                                      </Descriptions.Item>
                                      {!state?.delete && (
                                        <Descriptions.Item label="Actions">
                                          {interriorData.find(
                                            x =>
                                              x?.position?.code ===
                                              "inside_right_side"
                                          )?.original ? (
                                            <Popconfirm
                                              title="Attention!"
                                              description={
                                                <>
                                                  Are you sure you want to
                                                  delete the saved design for
                                                  order number <br />
                                                  <span
                                                    style={{
                                                      fontWeight: "700",
                                                    }}
                                                  >
                                                    {state?.orderId}
                                                  </span>{" "}
                                                </>
                                              }
                                              onConfirm={() => {
                                                let find = interriorData.find(
                                                  x =>
                                                    x?.position?.code ===
                                                    "inside_right_side"
                                                )
                                                deleteOriginalImages(find)
                                              }}
                                              okText="Yes"
                                              cancelText="No"
                                            >
                                              <Button
                                                disabled={deleteBtn}
                                                type="button"
                                                className={
                                                  "btn btn-danger d-flex align-items-center justify-content-center"
                                                }
                                                icon={<ClearOutlined />}
                                                style={{
                                                  margin: "15px auto 0 auto",
                                                }}
                                              >
                                                Delete{" "}
                                              </Button>
                                            </Popconfirm>
                                          ) : (
                                            <Button
                                              type="button"
                                              disabled={deleteBtn}
                                              className={
                                                "btn btn_tertiary d-flex align-items-center justify-content-center"
                                              }
                                              icon={<ClearOutlined />}
                                              onClick={() => {
                                                let filter = backData.filter(
                                                  x =>
                                                    x?.position?.code !==
                                                    "inside_right_side"
                                                )
                                                setInterriorData(filter)
                                              }}
                                              style={{
                                                margin: "15px auto 0 auto",
                                              }}
                                            >
                                              Delete{" "}
                                            </Button>
                                          )}
                                        </Descriptions.Item>
                                      )}
                                    </Descriptions>
                                  </Col>
                                )}
                              </Row>
                            )}
                          </div>
                        )
                      })}
                    </Card>
                  </Col>
                )}
                {spinToolsLeatherData.length > 0 && (
                  <Col lg={8}>
                    <Card className={"mb-3"} hoverable>
                      {spinToolsLeatherData.map((item, index) => {
                        if (item.type === 40) {
                          return (
                            <div key={index}>
                              <style>
                                {`
                                    .svg${index} .gs0 .st_0,
                                    .svg${index} .gs0 .st0,
                                    .svg${index} .gs1 .st_0,
                                    .svg${index} g polygon,
                                    .svg${index} polyline,
                                    circle{
                                      fill:${item.colorHexCode}!important;
                                      stroke:black;
                                      stroke-width:25px!important;
                                    }
                                    g g .st1,
                                    g g .st2,
                                    g g .st3,
                                    g g .st4,
                                    g g .st5{
                                      fill:white!important;
                                      stroke:red;
                                      stroke-width:25px!important;
                                    }
                                    .svg${index}  polygon, polygon{
                                      fill:white!important;
                                      stroke:black;
                                      stroke-width:25px!important;
                                   }
                                    `}
                              </style>
                              {item.positionCodes.find(x => x === "spine") && (
                                <Row
                                  gutter={[16, 0]}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "start",
                                  }}
                                >
                                  {!state?.delete && (
                                    <Col lg={24}>
                                      <Form.Item
                                        name="guitar_pick"
                                        label="Spine Design"
                                      >
                                        <Upload
                                          maxCount={1}
                                          beforeUpload={file => {
                                            file.url = URL.createObjectURL(file)
                                            let find = leatherPosition.find(
                                              x => x.code === "spine"
                                            )
                                            handleAcceptedFiles(
                                              file,
                                              item,
                                              find
                                            )
                                            return false
                                          }}
                                          fileList={[]}
                                          disabled={
                                            spineData.find(
                                              x => x?.position?.code === "spine"
                                            ) &&
                                            spineData.find(
                                              x => x?.position?.code === "spine"
                                            ).files.url
                                          }
                                        >
                                          <Button
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                            icon={<UploadOutlined />}
                                            disabled={
                                              spineData.find(
                                                x =>
                                                  x?.position?.code === "spine"
                                              ) &&
                                              spineData.find(
                                                x =>
                                                  x?.position?.code === "spine"
                                              ).files.url
                                            }
                                          >
                                            {spineData.find(
                                              x => x?.position?.code === "spine"
                                            )
                                              ? spineData.find(
                                                  x =>
                                                    x?.position?.code ===
                                                    "spine"
                                                ).files.name
                                              : "Select Design"}
                                          </Button>
                                        </Upload>
                                      </Form.Item>
                                    </Col>
                                  )}

                                  {spineData.find(
                                    x => x?.position?.code === "spine"
                                  ) && (
                                    <Col lg={24}>
                                      <Descriptions
                                        title={
                                          state?.delete ? "Spine Design" : ""
                                        }
                                        bordered
                                        layout="vertical"
                                        className="mb-3"
                                      >
                                        <Descriptions.Item label="Template">
                                          <SvgComponent
                                            svgUrl={item.imageUrl}
                                            className={"svg" + index}
                                            width={100}
                                            height={100}
                                          />
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Design">
                                          <Image
                                            width={100}
                                            src={
                                              spineData.find(
                                                x =>
                                                  x?.position?.code === "spine"
                                              ).files.url
                                            }
                                          />
                                        </Descriptions.Item>
                                        {!state?.delete && (
                                          <Descriptions.Item label="Actions">
                                            {spineData.find(
                                              x => x?.position?.code === "spine"
                                            )?.original ? (
                                              <Popconfirm
                                                title="Attention!"
                                                description={
                                                  <>
                                                    Are you sure you want to
                                                    delete the saved design for
                                                    order number <br />
                                                    <span
                                                      style={{
                                                        fontWeight: "700",
                                                      }}
                                                    >
                                                      {state?.orderId}
                                                    </span>{" "}
                                                  </>
                                                }
                                                onConfirm={() => {
                                                  let find = spineData.find(
                                                    x =>
                                                      x?.position?.code ===
                                                      "spine"
                                                  )
                                                  deleteOriginalImages(find)
                                                }}
                                                okText="Yes"
                                                cancelText="No"
                                              >
                                                <Button
                                                  disabled={deleteBtn}
                                                  type="button"
                                                  className={
                                                    "btn btn-danger d-flex align-items-center justify-content-center"
                                                  }
                                                  icon={<ClearOutlined />}
                                                  style={{
                                                    margin: "15px auto 0 auto",
                                                  }}
                                                >
                                                  Delete{" "}
                                                </Button>
                                              </Popconfirm>
                                            ) : (
                                              <Button
                                                type="button"
                                                className={
                                                  "btn btn_tertiary d-flex align-items-center justify-content-center"
                                                }
                                                icon={<ClearOutlined />}
                                                onClick={() => {
                                                  let filter = spineData.filter(
                                                    x =>
                                                      x?.position?.code !==
                                                      "spine"
                                                  )
                                                  setSpineData(filter)
                                                }}
                                                style={{
                                                  margin: "15px auto 0 auto",
                                                }}
                                              >
                                                Delete{" "}
                                              </Button>
                                            )}
                                          </Descriptions.Item>
                                        )}
                                      </Descriptions>
                                    </Col>
                                  )}
                                </Row>
                              )}
                            </div>
                          )
                        } else if (item.type === 50) {
                          return (
                            <div key={index}>
                              <style>
                                {`
                                      .svg${index} .gs0 .st_0,
                                      .svg${index} .gs0 .st0,
                                      .svg${index} .gs1 .st_0,
                                      .svg${index} g polygon,
                                      .svg${index} polyline,
                                      circle{
                                        fill:${item.colorHexCode}!important;
                                        stroke:black;
                                        stroke-width:25px!important;
                                      }
                                      g g .st1,
                                      g g .st2,
                                      g g .st3,
                                      g g .st4,
                                      g g .st5{
                                        fill:white!important;
                                        stroke:red;
                                        stroke-width:25px!important;
                                      }
                                      .svg${index}  polygon, polygon{
                                        fill:white!important;
                                        stroke:black;
                                        stroke-width:25px!important;
                                     }
                                      `}
                              </style>
                              {item.positionCodes.find(x => x === "tools") && (
                                <Row
                                  gutter={[16, 0]}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "start",
                                  }}
                                >
                                  {!state?.delete && (
                                    <Col lg={24}>
                                      <Form.Item
                                        name="tools"
                                        label="Tools Design"
                                      >
                                        <Upload
                                          maxCount={1}
                                          beforeUpload={file => {
                                            file.url = URL.createObjectURL(file)
                                            let find = leatherPosition.find(
                                              x => x.code === "tools"
                                            )
                                            handleAcceptedFiles(
                                              file,
                                              item,
                                              find
                                            )
                                            return false
                                          }}
                                          fileList={[]}
                                          disabled={
                                            toolsData.find(
                                              x => x?.position?.code === "tools"
                                            ) &&
                                            toolsData.find(
                                              x => x?.position?.code === "tools"
                                            ).files.url
                                          }
                                        >
                                          <Button
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                            icon={<UploadOutlined />}
                                            disabled={
                                              toolsData.find(
                                                x =>
                                                  x?.position?.code === "tools"
                                              ) &&
                                              toolsData.find(
                                                x =>
                                                  x?.position?.code === "tools"
                                              ).files.url
                                            }
                                          >
                                            {toolsData.find(
                                              x => x?.position?.code === "tools"
                                            )
                                              ? toolsData.find(
                                                  x =>
                                                    x?.position?.code ===
                                                    "tools"
                                                ).files.name
                                              : "Select Design"}
                                          </Button>
                                        </Upload>
                                      </Form.Item>
                                    </Col>
                                  )}

                                  {toolsData.find(
                                    x => x?.position?.code === "tools"
                                  ) && (
                                    <Col lg={24}>
                                      <Descriptions
                                        title={
                                          state?.delete ? "Tools Design" : ""
                                        }
                                        bordered
                                        layout="vertical"
                                        className="mb-3"
                                      >
                                        <Descriptions.Item label="Template">
                                          <SvgComponent
                                            svgUrl={item.imageUrl}
                                            className={"svg" + index}
                                            width={100}
                                            height={100}
                                          />
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Design">
                                          <Image
                                            width={100}
                                            src={
                                              toolsData.find(
                                                x =>
                                                  x?.position?.code === "tools"
                                              ).files.url
                                            }
                                          />
                                        </Descriptions.Item>
                                        {!state?.delete && (
                                          <Descriptions.Item label="Actions">
                                            {toolsData.find(
                                              x => x?.position?.code === "tools"
                                            )?.original ? (
                                              <Popconfirm
                                                title="Attention!"
                                                description={
                                                  <>
                                                    Are you sure you want to
                                                    delete the saved design for
                                                    order number <br />
                                                    <span
                                                      style={{
                                                        fontWeight: "700",
                                                      }}
                                                    >
                                                      {state?.orderId}
                                                    </span>{" "}
                                                  </>
                                                }
                                                onConfirm={() => {
                                                  let find = toolsData.find(
                                                    x =>
                                                      x?.position?.code ===
                                                      "tools"
                                                  )
                                                  deleteOriginalImages(find)
                                                }}
                                                okText="Yes"
                                                cancelText="No"
                                              >
                                                <Button
                                                  disabled={deleteBtn}
                                                  type="button"
                                                  className={
                                                    "btn btn-danger d-flex align-items-center justify-content-center"
                                                  }
                                                  icon={<ClearOutlined />}
                                                  style={{
                                                    margin: "15px auto 0 auto",
                                                  }}
                                                >
                                                  Delete{" "}
                                                </Button>
                                              </Popconfirm>
                                            ) : (
                                              <Button
                                                type="button"
                                                className={
                                                  "btn btn_tertiary d-flex align-items-center justify-content-center"
                                                }
                                                icon={<ClearOutlined />}
                                                onClick={() => {
                                                  let filter = toolsData.filter(
                                                    x =>
                                                      x?.position?.code !==
                                                      "tools"
                                                  )
                                                  setToolsData(filter)
                                                }}
                                                style={{
                                                  margin: "15px auto 0 auto",
                                                }}
                                              >
                                                Delete{" "}
                                              </Button>
                                            )}
                                          </Descriptions.Item>
                                        )}
                                      </Descriptions>
                                    </Col>
                                  )}
                                </Row>
                              )}
                            </div>
                          )
                        }
                      })}
                    </Card>
                  </Col>
                )}
                {stonesGuitarLeatherData.length > 0 && (
                  <Col lg={8}>
                    <Card className={"mb-3"} hoverable>
                      {stonesGuitarLeatherData.map((item, index) => {
                        if (item.type === 60) {
                          return (
                            <div key={index}>
                              <style>
                                {`
                                    .svg${index} .gs0 .st_0,
                                    .svg${index} .gs0 .st0,
                                    .svg${index} .gs1 .st_0,
                                    .svg${index} g polygon,
                                    .svg${index} polyline,
                                   circle{
                                      fill:${item.colorHexCode}!important;
                                      stroke:black;
                                      stroke-width:25px!important;
                                    }
                                    g g .st1,
                                    g g .st2,
                                    g g .st3,
                                    g g .st4,
                                    g g .st5{
                                      fill:white!important;
                                      stroke:red;
                                      stroke-width:25px!important;
                                    }
                                    .svg${index}  polygon, polygon{
                                      fill:white!important;
                                      stroke:black;
                                      stroke-width:25px!important;
                                   }
                                    `}
                              </style>
                              {item.positionCodes.find(x => x === "stones") && (
                                <Row
                                  gutter={[16, 0]}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "start",
                                  }}
                                >
                                  {!state?.delete && (
                                    <Col lg={24}>
                                      <Form.Item
                                        name="stones"
                                        label="Stones Design"
                                      >
                                        <Upload
                                          maxCount={1}
                                          beforeUpload={file => {
                                            file.url = URL.createObjectURL(file)
                                            let find = leatherPosition.find(
                                              x => x.code === "stones"
                                            )
                                            handleAcceptedFiles(
                                              file,
                                              item,
                                              find
                                            )
                                            return false
                                          }}
                                          fileList={[]}
                                          disabled={
                                            stonesData.find(
                                              x =>
                                                x?.position?.code === "stones"
                                            ) &&
                                            stonesData.find(
                                              x =>
                                                x?.position?.code === "stones"
                                            ).files.url
                                          }
                                        >
                                          <Button
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                            icon={<UploadOutlined />}
                                            disabled={
                                              stonesData.find(
                                                x =>
                                                  x?.position?.code === "stones"
                                              ) &&
                                              stonesData.find(
                                                x =>
                                                  x?.position?.code === "stones"
                                              ).files.url
                                            }
                                          >
                                            {stonesData.find(
                                              x =>
                                                x?.position?.code === "stones"
                                            )
                                              ? stonesData.find(
                                                  x =>
                                                    x?.position?.code ===
                                                    "stones"
                                                ).files.name
                                              : "Select Design"}
                                          </Button>
                                        </Upload>
                                      </Form.Item>
                                    </Col>
                                  )}
                                  {stonesData.find(
                                    x => x?.position?.code === "stones"
                                  ) && (
                                    <Col lg={24}>
                                      <Descriptions
                                        title={
                                          state?.delete ? "Stones Design" : ""
                                        }
                                        bordered
                                        layout="vertical"
                                        className="mb-3"
                                      >
                                        <Descriptions.Item label="Template">
                                          <SvgComponent
                                            svgUrl={item.imageUrl}
                                            className={"svg" + index}
                                            width={100}
                                            height={100}
                                          />
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Design">
                                          <Image
                                            width={100}
                                            src={
                                              stonesData.find(
                                                x =>
                                                  x?.position?.code === "stones"
                                              ).files.url
                                            }
                                          />
                                        </Descriptions.Item>
                                        {!state?.delete && (
                                          <Descriptions.Item label="Actions">
                                            {stonesData.find(
                                              x =>
                                                x?.position?.code === "stones"
                                            )?.original ? (
                                              <Popconfirm
                                                title="Attention!"
                                                description={
                                                  <>
                                                    Are you sure you want to
                                                    delete the saved design for
                                                    order number <br />
                                                    <span
                                                      style={{
                                                        fontWeight: "700",
                                                      }}
                                                    >
                                                      {state?.orderId}
                                                    </span>{" "}
                                                  </>
                                                }
                                                onConfirm={() => {
                                                  let find = stonesData.find(
                                                    x =>
                                                      x?.position?.code ===
                                                      "stones"
                                                  )
                                                  deleteOriginalImages(find)
                                                }}
                                                okText="Yes"
                                                cancelText="No"
                                              >
                                                <Button
                                                  disabled={deleteBtn}
                                                  type="button"
                                                  className={
                                                    "btn btn-danger d-flex align-items-center justify-content-center"
                                                  }
                                                  icon={<ClearOutlined />}
                                                  style={{
                                                    margin: "15px auto 0 auto",
                                                  }}
                                                >
                                                  Delete{" "}
                                                </Button>
                                              </Popconfirm>
                                            ) : (
                                              <Button
                                                type="button"
                                                disabled={deleteBtn}
                                                className={
                                                  "btn btn_tertiary d-flex align-items-center justify-content-center"
                                                }
                                                icon={<ClearOutlined />}
                                                onClick={() => {
                                                  let filter =
                                                    stonesData.filter(
                                                      x =>
                                                        x?.position?.code !==
                                                        "stones"
                                                    )
                                                  setStonesData(filter)
                                                }}
                                                style={{
                                                  margin: "15px auto 0 auto",
                                                }}
                                              >
                                                Delete{" "}
                                              </Button>
                                            )}
                                          </Descriptions.Item>
                                        )}
                                      </Descriptions>
                                    </Col>
                                  )}
                                </Row>
                              )}
                            </div>
                          )
                        } else if (item.type === 70) {
                          return (
                            <div key={index}>
                              <style>
                                {`
                                      .svg${index} .gs0 .st_0,
                                      .svg${index} .gs0 .st0,
                                      .svg${index} .gs1 .st_0,
                                      .svg${index} g polygon,
                                      .svg${index} polyline,
                                      circle{
                                        fill:${item.colorHexCode}!important;
                                        stroke:black;
                                        stroke-width:25px!important;
                                      }
                                      g g .st1,
                                      g g .st2,
                                      g g .st3,
                                      g g .st4,
                                      g g .st5{
                                        fill:white!important;
                                        stroke:red;
                                        stroke-width:25px!important;
                                      }
                                      .svg${index}  polygon, polygon{
                                        fill:white!important;
                                        stroke:black;
                                        stroke-width:25px!important;
                                     }
                                      `}
                              </style>
                              {item.positionCodes.find(
                                x => x === "guitar_pick"
                              ) && (
                                <Row
                                  gutter={[16, 0]}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "start",
                                  }}
                                >
                                  {!state?.delete && (
                                    <Col lg={24}>
                                      <Form.Item
                                        name="guitar_pick"
                                        label="Guitar Pick Design"
                                      >
                                        <Upload
                                          maxCount={1}
                                          beforeUpload={file => {
                                            file.url = URL.createObjectURL(file)
                                            let find = leatherPosition.find(
                                              x => x.code === "guitar_pick"
                                            )
                                            handleAcceptedFiles(
                                              file,
                                              item,
                                              find
                                            )
                                            return false
                                          }}
                                          fileList={[]}
                                          disabled={
                                            guitarData.find(
                                              x =>
                                                x?.position?.code ===
                                                "guitar_pick"
                                            ) &&
                                            guitarData.find(
                                              x =>
                                                x?.position?.code ===
                                                "guitar_pick"
                                            ).files.url
                                          }
                                        >
                                          <Button
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                            icon={<UploadOutlined />}
                                            disabled={
                                              guitarData.find(
                                                x =>
                                                  x?.position?.code ===
                                                  "guitar_pick"
                                              ) &&
                                              guitarData.find(
                                                x =>
                                                  x?.position?.code ===
                                                  "guitar_pick"
                                              ).files.url
                                            }
                                          >
                                            {guitarData.find(
                                              x =>
                                                x?.position?.code ===
                                                "guitar_pick"
                                            )
                                              ? guitarData.find(
                                                  x =>
                                                    x?.position?.code ===
                                                    "guitar_pick"
                                                ).files.name
                                              : "Select Design"}
                                          </Button>
                                        </Upload>
                                      </Form.Item>
                                    </Col>
                                  )}

                                  {guitarData.find(
                                    x => x?.position?.code === "guitar_pick"
                                  ) && (
                                    <Col lg={24}>
                                      <Descriptions
                                        title={
                                          state?.delete
                                            ? "Guitar Pick Design"
                                            : ""
                                        }
                                        bordered
                                        layout="vertical"
                                        className="mb-3"
                                      >
                                        <Descriptions.Item label="Template">
                                          <SvgComponent
                                            svgUrl={item.imageUrl}
                                            className={"svg" + index}
                                            width={100}
                                            height={100}
                                          />
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Design">
                                          <Image
                                            width={100}
                                            src={
                                              guitarData.find(
                                                x =>
                                                  x?.position?.code ===
                                                  "guitar_pick"
                                              ).files.url
                                            }
                                          />
                                        </Descriptions.Item>
                                        {!state?.delete && (
                                          <Descriptions.Item label="Actions">
                                            {guitarData.find(
                                              x =>
                                                x?.position?.code ===
                                                "guitar_pick"
                                            )?.original ? (
                                              <Popconfirm
                                                title="Attention!"
                                                description={
                                                  <>
                                                    Are you sure you want to
                                                    delete the saved design for
                                                    order number <br />
                                                    <span
                                                      style={{
                                                        fontWeight: "700",
                                                      }}
                                                    >
                                                      {state?.orderId}
                                                    </span>{" "}
                                                  </>
                                                }
                                                onConfirm={() => {
                                                  let find = guitarData.find(
                                                    x =>
                                                      x?.position?.code ===
                                                      "guitar_pick"
                                                  )
                                                  deleteOriginalImages(find)
                                                }}
                                                okText="Yes"
                                                cancelText="No"
                                              >
                                                <Button
                                                  disabled={deleteBtn}
                                                  type="button"
                                                  className={
                                                    "btn btn-danger d-flex align-items-center justify-content-center"
                                                  }
                                                  icon={<ClearOutlined />}
                                                  style={{
                                                    margin: "15px auto 0 auto",
                                                  }}
                                                >
                                                  Delete{" "}
                                                </Button>
                                              </Popconfirm>
                                            ) : (
                                              <Button
                                                type="button"
                                                className={
                                                  "btn btn_tertiary d-flex align-items-center justify-content-center"
                                                }
                                                icon={<ClearOutlined />}
                                                onClick={() => {
                                                  let filter =
                                                    guitarData.filter(
                                                      x =>
                                                        x?.position?.code !==
                                                        "guitar_pick"
                                                    )
                                                  setGuitarData(filter)
                                                }}
                                                style={{
                                                  margin: "15px auto 0 auto",
                                                }}
                                              >
                                                Delete{" "}
                                              </Button>
                                            )}
                                          </Descriptions.Item>
                                        )}
                                      </Descriptions>
                                    </Col>
                                  )}
                                </Row>
                              )}
                            </div>
                          )
                        }
                      })}
                    </Card>
                  </Col>
                )}
              </Row>
              <Row gutter={[16, 0]}>
                <Col span={24}>
                  <Form.Item
                    name="notes"
                    label="Notes"
                    style={{ width: "100%" }}
                  >
                    <TextArea rows={4} />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </div>
      </Spin>
    </React.Fragment>
  )
}

export default DesignUpload
