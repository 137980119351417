import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Upload,
  Card,
  Spin,
  Form,
  Input,
  Button,
  Table,
  Result,
  Tag,
  InputNumber,
  Collapse,
  Divider,
  Progress,
  Alert,
  Select,
  Tooltip,
  Popconfirm,
  Checkbox,
  Typography,
} from "antd"
import * as Http from "utils/http.helper"
import toastr from "toastr"
import tiff from "assets/images/tiff.png"
import pdfImage from "assets/images/pdf.png"
import { s3 } from "utils/aws"
import { fromUrl } from "geotiff"
import Sounds from "utils/sounds"
import axios from "axios"
import "toastr/build/toastr.min.css"
import { saveAs } from "file-saver" // This package helps in saving files
import ImageComponent from "components/image"
import {
  SearchOutlined,
  ClearOutlined,
  DownloadOutlined,
  MenuUnfoldOutlined,
  CloseCircleOutlined,
  LoadingOutlined,
  ClockCircleOutlined,
  UploadOutlined,
  CheckSquareFilled,
  CloseSquareFilled,
} from "@ant-design/icons"
import moment from "moment-timezone"
const { TextArea } = Input
const { Panel } = Collapse
const { Paragraph } = Typography

const orderStatus = [
  {
    value: 10,
    label: "Üretime Hazır",
  },
  {
    value: 20,
    label: "İşleme Alındı",
  },
  {
    value: 30,
    label: "Completed",
  },
  {
    value: 40,
    label: "Shipment",
  },
  {
    value: 100,
    label: "Deleted",
  },
]
const css = `
.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}
  .upload-list-inline .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-inline-end: 8px;
}
.ant-upload, .ant-upload button{
  width:100%!important
}
.ant-upload-rtl.upload-list-inline .ant-upload-list-item {
  float: right;
}
.ant-table-row-level-1{
  background:#fafafa!important;
  cursor:pointer;
}
.multipleRow td{
  font-weight:500!important
}
.my-card .ant-card-body{
  padding:10px!important
}`

const UVDTFList = () => {
  document.title = "UVDTF List | EASY JET CONNECT"
  const [form] = Form.useForm()
  const [searchForm] = Form.useForm()
  const [tableForm] = Form.useForm()
  const [pageLoading, setPageLoading] = useState(true)
  const [data, setData] = useState([])
  const [fileList, setFileList] = useState([])
  const [cost, setCost] = useState(null)
  const [uploadProgress, setUploadProgress] = useState([])

  const [downModal, setDownModal] = useState(false)
  const [downProgress, setDownProgress] = useState([])
  const [disabledBtnUpload, setDisabledBtnUpload] = useState(true)
  const [downProcessStop, setDownProcessStop] = useState(false)
  const [companyDataList, setCompanyDataList] = useState([])
  const [downLoading, setDownloading] = useState(false)
  const [labelUploadFile, setLabelUploadFile] = useState(null)
  const [pickUp, setPickUp] = useState(false)
  const [pickUpSearch, setPickUpSearch] = useState(false)
  const [tableParams, setTableParams] = useState({
    current: 0,
    pageSize: 0,
    total: 0,
  })

  const getCost = () => {
    Http.get(`api/uvdtf/GetUvdtfPriceCoefficientByCustomerId`)
      .then(res => {
        if (res?.coefficient) {
          setCost(res.coefficient)
          getData(null)
          setPageLoading(false)
        } else {
          setPageLoading(false)
          setCost(0)
        }
      })
      .catch(() => {
        setPageLoading(false)
        return false
      })
  }

  const getCompanyData = () => {
    Http.get("api/companies/getAllCustomers?categoryId=4")
      .then(res => {
        if (res?.success) {
          setPageLoading(false)
          setCompanyDataList(
            res?.data.map(item => {
              return { label: item.name, value: item.id }
            })
          )
          searchForm.setFieldsValue({
            companyId: res?.data[0].id,
          })

          setTimeout(() => {
            getData()
          }, 200)
        }
        setPageLoading(false)
      })
      .catch(() => {
        setPageLoading(false)
        return false
      })
  }

  const getData = pagination => {
    const values = searchForm.getFieldsValue()
    setPageLoading(true)
    Http.get(
      `api/orders/getUvdtfOrders?${
        values?.companyId
          ? `customerId=${values.companyId}`
          : JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 2
          ? ""
          : `customerId=${
              JSON.parse(localStorage.getItem("authUserV0"))?.companyId
            }`
      }${
        JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 2
          ? ""
          : `&pickUp=${pickUpSearch ? true : ""}`
      }&PageNumber=${pagination?.current ? pagination.current : 1}&Limit=${
        pagination?.pageSize ? pagination.pageSize : 10
      }`
    )
      .then(res => {
        if (res?.data?.data?.length > 0) {
          res?.data?.data.map(item => {
            let totalQuantity = 0
            let totalLength = 0
            let totalPrice = 0
            item.images.map(x => {
              totalQuantity += x.quantity
              totalLength += x.length
              totalPrice += x.price
              x.fileName = "File Name: " + x.imgUrl.split("uvdtf/")[1]
            })
            item.quantity = totalQuantity
            item.children = item.images
            item.length = totalLength
            item.price = totalPrice
            delete item.images
          })
          setData(res?.data?.data.reverse())
          setTableParams({
            current: pagination?.current ? pagination.current : 1,
            pageSize: pagination?.pageSize ? pagination.pageSize : 10,
            total:
              res?.data?.totalItems !== 0
                ? res?.data?.totalItems
                : tableParams.total,
          })
          setPageLoading(false)
        } else {
          setData([])
          setPageLoading(false)
          setTableParams({
            current: 1,
            pageSize: 10,
            total: 0,
          })
        }
      })
      .catch(() => {
        setPageLoading(false)
        return false
      })
  }

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 2) {
      getCost()
    } else {
      getCompanyData()
    }
  }, [])

  const handleTableChange = pagination => {
    setTableParams({
      current: pagination.current,
      pageSize: pagination.pageSize,
      total: tableParams.total,
    })
    if (
      tableParams.current !== pagination.current ||
      tableParams.pageSize !== pagination.pageSize
    ) {
      getData({
        current: pagination.current,
        pageSize: pagination.pageSize,
      })
    }
  }
  function generateCustomId(length) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
    let result = ""
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length))
    }
    return result
  }

  const handleUploadTiff = async (file, index) => {
    try {
      setPageLoading(true)
      const { quantity } = form.getFieldsValue()
      const tiff = await fromUrl(URL.createObjectURL(file))
      const image = await tiff.getImage()
      const fileDirectory = image.getFileDirectory() // TIFF dosyasının metadata'sını al
      const dpiX = fileDirectory.XResolution
        ? fileDirectory.XResolution[0] / fileDirectory.XResolution[1]
        : 300
      const dpiY = fileDirectory.YResolution
        ? fileDirectory.YResolution[0] / fileDirectory.YResolution[1]
        : 300
      const inchPerPy = 1 / dpiY
      const inchPerPx = 1 / dpiX
      const width = fileDirectory.ImageWidth
      const height = fileDirectory.ImageLength
      const heightInInch = (height * inchPerPy).toFixed(3)
      const widthInInch = (width * inchPerPx).toFixed(3)

      if (widthInInch < 22.000000001) {
        const customId = generateCustomId(4) // 10 karakter uzunluğunda özel bir tanımlayıcı oluşturur
        let total = (heightInInch * cost * quantity).toFixed(2)
        const saveImgUrl = `uvdtf/${file.name
          .split(".")[0]
          .replaceAll(" ", "_")}_${customId}.${file.name.split(".").pop()}`

        const createFileObject = (id, width, height) => ({
          key: customId,
          id,
          height,
          width,
          fileName: file.name,
          quantity: quantity,
          price: cost,
          total,
          type: "tiff",
          imgUrl: file.name,
          saveImgUrl,
        })

        setFileList(prevList => [
          ...prevList,
          createFileObject(
            prevList.length > 0 ? prevList[prevList.length - 1].id + 1 : 1,
            widthInInch,
            heightInInch
          ),
        ])
        setPageLoading(false)
        handleSaveImageAws(file, index, customId, file.name.split(".").pop())
      } else {
        toastr.error(
          `${widthInInch} inc genişliğe sahip bir dosya yükleyemezsiniz. Genişlik en fazla 22 inc olmalıdır.`
        )
        setPageLoading(false)
      }
    } catch (error) {
      setPageLoading(false)
      toastr.error("Bir hata oluştu. Lütfen tekrar deneyin.")
    }
  }

  const handleUploadImage = async (file, saveFile, index) => {
    setPageLoading(true)
    const { quantity } = form.getFieldsValue()
    const reader = new FileReader()
    reader.onload = e => {
      const image = new Image()
      image.onload = () => {
        // Görüntü yüklendiğinde yüksekliği alın
        const width = image.width
        const height = image.height
        const DPI = 300 // Varsayılan DPI değeri
        const inchPerPx = 1 / DPI
        // Genişliği ve yüksekliği inç cinsine dönüştür
        const heightInInch = (height * inchPerPx).toFixed(3)
        const widthInInch = (width * inchPerPx).toFixed(3)
        if (widthInInch < 22.00001) {
          let total = (heightInInch * cost * quantity).toFixed(2)
          const customId = generateCustomId(4) // 10 karakter uzunluğunda özel bir tanımlayıcı oluşturur
          if (fileList.length === 0) {
            setFileList([
              {
                key: customId,
                id: 1,
                height: heightInInch,
                width: widthInInch,
                fileName: saveFile.name,
                quantity: 1,
                price: cost,
                total: total,
                imgUrl: URL.createObjectURL(file.originFileObj),
                saveImgUrl: `uvdtf/${file.name
                  .split(".")[0]
                  .replaceAll(" ", "_")}_${customId}.${file.name
                  .split(".")
                  .pop()}`,
                type: "all",
              },
            ])
            handleSaveImageAws(
              file.originFileObj,
              index,
              customId,
              file.name.split(".").pop()
            )
          } else {
            setFileList([
              ...fileList,
              {
                key: customId,
                id: fileList[fileList.length - 1].id + 1,
                height: heightInInch,
                width: widthInInch,
                fileName: saveFile.name,
                quantity: 1,
                price: cost,
                total: total,
                imgUrl: URL.createObjectURL(file.originFileObj),
                saveImgUrl: `uvdtf/${file.name
                  .split(".")[0]
                  .replaceAll(" ", "_")}_${customId}.${file.name
                  .split(".")
                  .pop()}`,
                type: "all",
              },
            ])
            handleSaveImageAws(
              file.originFileObj,
              index,
              customId,
              file.name.split(".").pop()
            )
          }
          setPageLoading(false)
        } else {
          toastr.error(
            `${widthInInch} inc genişliğe sahip bir dosya yükleyemezsiniz. Genişlik en fazla 22 inc olmalıdır.`
          )
          setPageLoading(false)
        }
      }
      image.src = e.target.result
    }
    reader.readAsDataURL(file.originFileObj)
    return false
  }

  const handleSaveImageAws = async (saveFile, index, uniqueId, ext) => {
    const params = {
      Bucket: "easyjetconnect",
      Key: `uvdtf/${saveFile.name
        .split(".")[0]
        .replaceAll(" ", "_")}_${uniqueId}.${ext}`,
      Body: saveFile,
    }

    const upload = s3.upload(params)

    let progress = 0

    // Progress event handler
    const onProgress = evt => {
      progress = Math.round((evt.loaded / evt.total) * 100)

      // Set upload progress based on index
      setUploadProgress(prev => {
        const updatedProgress = [...prev]
        updatedProgress[index] = progress
        return updatedProgress.filter(item => item !== undefined)
      })
    }

    // Async upload
    try {
      await new Promise((resolve, reject) => {
        upload.on("httpUploadProgress", onProgress)
        upload.send((err, data) => {
          if (err) {
            reject(err)
          } else {
            resolve(data)
          }
        })
      })
    } catch (error) {
      toastr.error(error)
    }
  }

  const onSaveLabel = async file => {
    setPageLoading(true)
    const customId = generateCustomId(4) // 10 karakter uzunluğunda özel bir tanımlayıcı oluşturur
    const params = {
      Bucket: "easyjetconnect",
      Key: `dtf/${file.name
        .split(".")[0]
        .replaceAll(" ", "_")}_${customId}.${file.name.split(".").pop()}`,
      Body: file.originFileObj,
    }
    const upload = s3.upload(params)

    const onProgress = evt => {
      Math.round((evt.loaded / evt.total) * 100)
    }
    try {
      await new Promise((resolve, reject) => {
        upload.on("httpUploadProgress", onProgress)
        upload.send((err, data) => {
          setLabelUploadFile(
            `dtf/${file.name
              .split(".")[0]
              .replaceAll(" ", "_")}_${customId}.${file.name.split(".").pop()}`
          )
          setPageLoading(false)
          if (err) {
            reject(err)
          } else {
            resolve(data)
          }
        })
      })
    } catch (error) {
      toastr.error(error)
      setPageLoading(false)
    }
  }

  const handleSave = () => {
    const values = form.getFieldsValue()
    setPageLoading(true)
    let obj = {
      productName: values.productName,
      labelFile: labelUploadFile ? labelUploadFile : "",
      pickUp: pickUp,
      note: values.notes ? values.notes : "",
      images: [],
    }
    fileList.map(item => {
      obj.images.push({
        imgUrl: item?.saveImgUrl,
        quantity: item.quantity,
        length: item.height,
        price: item.price,
      })
    })
    Http.post(`api/orders/AddUvdtfOrder`, obj)
      .then(res => {
        if (res.success) {
          getData()
          setPageLoading(false)
          setFileList([])
          setPickUp(false)
          setUploadProgress([])
          setLabelUploadFile(null)
          form.resetFields()
          toastr.success("UVDTF added successfully")
          form.setFieldsValue({ quantity: 1 })
        } else {
          setPageLoading(false)
          toastr.error(res?.Message)
        }
      })
      .catch(error => {
        toastr.error("UVDTF added failed.")
        setPageLoading(false)
      })
  }

  const columns = [
    {
      title: "Product Name",
      dataIndex: "productName",
      width: 250,
      render: (value, row) => {
        return value ? (
          <Paragraph copyable>{value}</Paragraph>
        ) : (
          <span style={{ opacity: "0.5" }}>{row?.fileName}</span>
        )
      },
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      width: 200,
      render: (value, row) => {
        return row?.productName ? (
          <span>Total: {value}</span>
        ) : (
          <span style={{ opacity: "0.5" }}>{value}</span>
        )
      },
    },
    {
      title: "Length (inc)",
      dataIndex: "length",
      width: 200,
      render: (value, row) => {
        return row?.productName ? (
          <span>
            Total: {value ? value + " inc" : <> {row?.length + " inc"}</>}
          </span>
        ) : value ? (
          <span style={{ opacity: "0.5" }}>{value + " inc"}</span>
        ) : (
          <span style={{ opacity: "0.5" }}>{row?.length + " inc"}</span>
        )
      },
    },
    {
      title: "Total Price",
      dataIndex: "price",
      width: 200,
      render: (value, row) => {
        return row?.productName ? (
          <span>Total: {value + " $"}</span>
        ) : (
          <span style={{ opacity: "0.5" }}>{value + " $"}</span>
        )
      },
    },
    {
      title: "Local Pickup",
      dataIndex: "pickUp",
      width: 80,
      align: "center",
      render: (value, row) => {
        if (row.productName) {
          return value ? (
            <CheckSquareFilled style={{ color: "#22bd37", fontSize: 24 }} />
          ) : (
            <CloseSquareFilled style={{ color: "#fb2525", fontSize: 24 }} />
          )
        }
      },
    },
    {
      title: "Label",
      dataIndex: "labelFile",
      width: 100,
      align: "center",
      render: (value, row) => {
        let url = `https://easyjetconnect.s3.eu-central-1.amazonaws.com/${value}`
        if (row.productName) {
          if (value.split(".")[value.split(".").length - 1] !== "pdf") {
            return value ? (
              <Button
                onClick={() => {
                  setDownModal(true)
                  downloadImageOne(url, value, 0, row)
                }}
                style={{
                  width: "100%",
                  height: "100%",
                  border: "none",
                }}
              >
                <ImageComponent imageUrl={url} width={40} preview={false} />
              </Button>
            ) : (
              <CloseSquareFilled style={{ color: "#fb2525", fontSize: 24 }} />
            )
          } else {
            return value ? (
              <Button
                href={url}
                download
                className="pdf_download"
                style={{
                  width: "100%",
                  height: "100%",
                  border: "none",
                }}
                onClick={() => {
                  if (
                    JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 7
                  ) {
                    if (
                      JSON.parse(localStorage.getItem("authUserV0"))?.actions
                        ?.length > 0 &&
                      ((JSON.parse(localStorage.getItem("authUserV0"))
                        ?.actions[0] === "Completed" &&
                        row.status === 20) ||
                        (JSON.parse(localStorage.getItem("authUserV0"))
                          ?.actions[0] === "Shipment" &&
                          row.status === 30))
                    ) {
                      onChangeStatus(row?.id)
                    }
                  }
                }}
              >
                <img
                  src={pdfImage}
                  alt=""
                  style={{
                    height: "100%",
                    width: "100%",
                    maxWidth: "30px",
                  }}
                />
              </Button>
            ) : (
              <CloseSquareFilled style={{ color: "#fb2525", fontSize: 24 }} />
            )
          }
        }
      },
    },
    {
      title: "Note",
      dataIndex: "note",
      render: value => {
        return value !== "undefined" ? value : ""
      },
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      width: 200,
      render: (_, row) => {
        return row.productName
          ? moment
              .utc(row.createdDate) // UTC'den başla
              .tz("America/Chicago") // Doğrudan ABD Doğu Zaman Dilimine çevir
              .format("DD.MM.YYYY HH:mm:ss")
          : ""
        // return moment(new Date(row.createdDate)).format("DD.MM.YYYY HH:mm:ss")
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 150,
      render: (_, row) => {
        if (row?.productName) {
          return (
            <Tag
              color={
                row.status === 10
                  ? "#55acee"
                  : row.status === 100
                  ? "#f50"
                  : row.status === 20
                  ? "#3b5999"
                  : row.status === 30
                  ? "#87d068"
                  : "#248113"
              }
              key={row.id}
              style={{
                width: "100%",
                textAlign: "center",
                fontWeight: "600",
              }}
            >
              {orderStatus.find(x => x.value === row.status)?.label}
            </Tag>
          )
        } else {
          return null
        }
      },
    },
    {
      title: "",
      dataIndex: "actions",
      hidden:
        JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 1
          ? false
          : JSON.parse(localStorage.getItem("authUserV0"))?.actions?.length >
              0 &&
            JSON.parse(localStorage.getItem("authUserV0"))?.actions[0] !==
              "Completed",
      render: (_, row) => {
        if (row.productName) {
          return (
            <div
              className="d-flex align-items-center justify-content-start"
              style={{ columnGap: 10 }}
            >
              {JSON.parse(localStorage.getItem("authUserV0"))?.roleId !== 2 && (
                <Tooltip
                  placement="top"
                  title={
                    JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 7
                      ? JSON.parse(localStorage.getItem("authUserV0"))
                          ?.actions[0] === "Shipment" && row.status === 20
                        ? "İndirme işlemi için ürünler Completed statüsünde olmalıdır."
                        : "Download Files"
                      : row.status === 20
                      ? "İndirme işlemi için ürünler Completed statüsünde olmalıdır."
                      : "Download Files"
                  }
                >
                  <Button
                    className={
                      JSON.parse(localStorage.getItem("authUserV0"))?.roleId ===
                      7
                        ? JSON.parse(localStorage.getItem("authUserV0"))
                            ?.actions[0] === "Shipment" && row.status === 20
                          ? "btn btn_disabled btn_primary d-flex align-items-center justify-content-center"
                          : "btn btn_primary d-flex align-items-center justify-content-center"
                        : "btn btn_primary d-flex align-items-center justify-content-center"
                    }
                    icon={<DownloadOutlined />}
                    disabled={downProcessStop}
                    onClick={() => {
                      if (
                        JSON.parse(localStorage.getItem("authUserV0"))?.actions
                          ?.length > 0 &&
                        JSON.parse(localStorage.getItem("authUserV0"))
                          ?.actions[0] === "Shipment" &&
                        row.status === 20
                      ) {
                        return false
                      } else {
                        downloadFile(row.id, row.productName)
                      }
                    }}
                  />
                </Tooltip>
              )}
              {row.status === 10 && (
                <Tooltip placement="top" title="Delete Product">
                  <Popconfirm
                    title="Attention!"
                    description={
                      <>
                        Are you sure you want to delete product{" "}
                        <span style={{ fontWeight: "700" }}>
                          {row.productName}
                        </span>{" "}
                      </>
                    }
                    onConfirm={() => {
                      onDelete(row.id)
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      type="button"
                      className="btn btn_quinary d-flex align-items-center justify-content-center"
                      icon={<ClearOutlined />}
                    />
                  </Popconfirm>
                </Tooltip>
              )}
              {row.status === 10 && (
                <Tooltip placement="top" title="Üretime Gönder">
                  <Button
                    className="btn btn_tertiary d-flex align-items-center justify-content-center"
                    onClick={() => onSend(row.id)}
                    icon={<MenuUnfoldOutlined />}
                  />
                </Tooltip>
              )}
            </div>
          )
        }
      },
    },
  ].filter(item => !item.hidden)

  let locale = {
    emptyText: (
      <Result
        title="No data"
        subTitle="If you haven't selected any company or added financial data yet, you can select and list a company or create a new financial value."
      />
    ),
  }

  const onSend = id => {
    setPageLoading(true)
    let obj = {
      id: id,
      categoryId: 4,
    }
    Http.post(`api/orders/sendDtfUvDtfForProduction`, obj)
      .then(res => {
        if (res.success) {
          getData(null)
          setPageLoading(false)
          toastr.success("Üretime gönderildi.")
        } else {
          setPageLoading(false)
          toastr.error("Beklenmedik Hata.")
        }
      })
      .catch(function (error) {
        setPageLoading(false)
      })
  }
  const onDelete = id => {
    setPageLoading(true)
    let obj = {
      id: id,
      categoryId: 4,
    }
    Http.post(`api/orders/deleteDtfUvDtfForProduction`, obj)
      .then(res => {
        if (res.success) {
          getData(null)
          setPageLoading(false)
          toastr.success("Silme Başarılı.")
        } else {
          setPageLoading(false)
          toastr.error("Beklenmedik Hata.")
        }
      })
      .catch(function (error) {
        setPageLoading(false)
      })
  }

  const downloadFile = async (id, name) => {
    setDownProcessStop(true)
    Http.get(`api/orders/GetDtfUvdtfImages?Id=${id}&categoryId=4`)
      .then(async res => {
        if (res.success) {
          Promise.all(
            res.data.map((item, index) => {
              setDownModal(true)
              return downloadImage(item.imageUrl, name, index)
            })
          ).then(() => {
            setDownProcessStop(false)
            setTimeout(() => {
              setDownModal(false)
              setDownProgress([])
            }, 750)
            // Tüm resimler başarıyla indirildiğinde loading'i kapat
            if (JSON.parse(localStorage.getItem("authUserV0")).roleId === 7) {
              onChangeStatus(id)
            }
          })
        } else {
        }
      })
      .catch(function (error) {
        toastr.error(error?.message, error?.code)
      })
  }

  const downloadImage = async (imageUrl, name, index) => {
    const urlParts = imageUrl.split("/")
    const bucket = urlParts[2].split(".")[0]
    const key = urlParts.slice(3).join("/")
    const s3Url = `https://${bucket}.s3.amazonaws.com/${key}`
    try {
      const response = await axios({
        url: s3Url,
        method: "GET",
        responseType: "blob",
        onDownloadProgress: evt => {
          let prg = Math.round((evt.loaded / evt.total) * 100)
          setDownProgress(prevProgress => {
            const newProgress = [...prevProgress]
            const existingIndex = newProgress.findIndex(p => p.id === index)
            if (existingIndex >= 0) {
              newProgress[existingIndex].value = prg
            } else {
              newProgress.push({
                id: index,
                name: imageUrl.split("uvdtf/")[1],
                value: prg,
              })
            }
            return newProgress
          })
        },
      })

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      })
      saveAs(blob, name + "_" + key.split("/").pop())
    } catch (error) {
      setDownloading(false)
      toastr.error(error?.message, error?.code)
    }
  }

  const downloadImageOne = async (imageUrl, name, index, row) => {
    setDownloading(true)
    const urlParts = imageUrl.split("/")
    const bucket = urlParts[2].split(".")[0]
    const key = urlParts.slice(3).join("/")
    const s3Url = `https://${bucket}.s3.amazonaws.com/${key}`
    try {
      const response = await axios({
        url: s3Url,
        method: "GET",
        responseType: "blob",
        onDownloadProgress: evt => {
          let prg = Math.round((evt.loaded / evt.total) * 100)
          setDownProgress(prevProgress => {
            const newProgress = [...prevProgress]
            const existingIndex = newProgress.findIndex(p => p.id === index)
            if (existingIndex >= 0) {
              newProgress[existingIndex].value = prg
            } else {
              newProgress.push({
                id: index,
                name: imageUrl.split("uvdtf/")[1],
                value: prg,
              })
            }
            return newProgress
          })
        },
      })

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      })
      saveAs(blob, name + "_" + key.split("/").pop())
      setDownloading(false)
      setDownModal(false)
      setDownProgress([])
      if (JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 7) {
        if (
          JSON.parse(localStorage.getItem("authUserV0"))?.actions?.length > 0 &&
          ((JSON.parse(localStorage.getItem("authUserV0"))?.actions[0] ===
            "Completed" &&
            row.status === 20) ||
            (JSON.parse(localStorage.getItem("authUserV0"))?.actions[0] ===
              "Shipment" &&
              row.status === 30))
        ) {
          onChangeStatus(row?.id)
        }
      }
    } catch (error) {
      setDownloading(false)
      toastr.error(error?.message, error?.code)
    }
  }

  const onChangeStatus = id => {
    setPageLoading(true)
    let obj = {
      id: id,
      categoryId: 4,
      status:
        JSON.parse(localStorage.getItem("authUserV0"))?.actions[0] ===
        "Shipment"
          ? 40
          : 30,
    }
    Http.post(`api/orders/ChangeDtfUvdtfOrderStatus`, obj)
      .then(res => {
        if (res.success) {
          getData(null)
          setPageLoading(false)
        } else {
          setPageLoading(false)
        }
      })
      .catch(function (error) {
        setPageLoading(false)
      })
  }

  const columnsFileList = [
    {
      title: "",
      dataIndex: "loading",
      width: 250,
      render: (_, record, index) => {
        return (
          <Progress
            percent={uploadProgress[index]}
            style={{ maxWidth: "100%" }}
          />
        )
      },
    },
    {
      title: "File Name",
      dataIndex: "fileName",
    },
    {
      title: "Image",
      dataIndex: "imgUrl",
      render: (value, record) => {
        return (
          <ImageComponent
            imageUrl={record.type === "tiff" ? tiff : value}
            preview={(record.type === "tiff") !== "tiff"}
            width={record.type === "tiff" ? 30 : "30px"}
            height={record.type === "tiff" ? 30 : "35px"}
          />
        )
      },
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
    },

    {
      title: "Length",
      dataIndex: "height",
      render: (_, record) => {
        return record.height + " inc"
      },
    },
    {
      title: "Price for 1 inch",
      dataIndex: "price",
      render: (_, record) => {
        return record.price + " $"
      },
    },
    {
      title: "Total",
      dataIndex: "total",
      render: (_, record) => {
        return record.total + " $"
      },
    },
    {
      title: "",
      dataIndex: "total",
      render: (_, record, index) => {
        return (
          <Tooltip placement="top" title="Delete">
            <Popconfirm
              title="Attention!"
              description={
                <>
                  Are you sure you want to delete? <br />{" "}
                  <span style={{ fontWeight: "700" }}>{record.fileName}</span>{" "}
                </>
              }
              onConfirm={() => {
                setUploadProgress(prev => {
                  const updatedProgress = [...prev]
                  updatedProgress.splice(index, 1) // index'teki elemanı kaldırır
                  return updatedProgress
                })
                let arr = []
                fileList.map((x, y) => {
                  if (x.key !== record.key) {
                    arr.push(x)
                  }
                })
                setFileList(arr)
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="button"
                disabled={!uploadProgress.every(progress => progress === 100)}
                className="btn btn_quinary d-flex align-items-center justify-content-center"
                icon={<ClockCircleOutlined />}
              />
            </Popconfirm>
          </Tooltip>
        )
      },
    },
  ]

  useEffect(() => {
    form.setFieldsValue({ quantity: 1 })
  }, [])

  return (
    <React.Fragment>
      <div className="page-content mb-3">
        <style>{css}</style>
        {downModal && downProgress.length > 0 && (
          <Card className="my_card">
            <Divider orientation="left">File Download Process Info</Divider>

            {downProgress.map(item => {
              return (
                <div
                  key={item.id}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: 10,
                  }}
                >
                  <div>
                    <Progress
                      percent={item.value}
                      style={{ minWidth: "300px" }}
                    />
                  </div>
                  <div>{item.name}</div>
                </div>
              )
            })}
          </Card>
        )}
        <Spin
          indicator={<LoadingOutlined spin />}
          spinning={pageLoading || downLoading}
          size="large"
        >
          {cost > 0 ||
          JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 1 ||
          JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 7 ? (
            <Collapse activeKey={["1"]} expandIcon={() => false}>
              <Panel
                key="1"
                header={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    UVDTF List
                    {cost > 0 && <div> * Price for 1 inch: {cost} $ </div>}
                  </div>
                }
              >
                {JSON.parse(localStorage.getItem("authUserV0"))?.roleId ===
                  2 && (
                  <Form
                    layout="vertical"
                    form={form}
                    name="dynamic_form_complex"
                    autoComplete="off"
                    className="mb-4"
                    initialValues={{
                      sizeList: fileList,
                    }}
                  >
                    <Row gutter={[16, 16]}>
                      <Col span={24} style={{ color: "red", opacity: "0.6" }}>
                        * Bu alanda yükleme yapmadan önce adet bilgisini mutlaka
                        giriniz. Başlangıçta quantity değerini değiştirmezseniz
                        kayıt işlemi içim default olarak 1 adet tabloya
                        eklenecektir.
                      </Col>{" "}
                      <Col span={3}>
                        <Form.Item
                          name="productName"
                          label="Product Name"
                          rules={[
                            {
                              required: true,
                              message: "Prodcut Name alanı zorunludur.",
                            },
                          ]}
                        >
                          <Input
                            onBlur={e => {
                              const { quantity } = form.getFieldsValue()
                              setDisabledBtnUpload(
                                e?.target?.value && quantity ? false : true
                              )
                            }}
                            onChange={e => {
                              const { quantity } = form.getFieldsValue()
                              setDisabledBtnUpload(
                                e?.target?.value && quantity ? false : true
                              )
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={3}>
                        <Form.Item
                          name="quantity"
                          label="Quantity"
                          rules={[
                            {
                              required: true,
                              message: "Quantity alanı zorunludur.",
                            },
                          ]}
                        >
                          <InputNumber
                            min={1}
                            style={{ width: "100%" }}
                            onBlur={e => {
                              const { productName } = form.getFieldsValue()
                              setDisabledBtnUpload(
                                e?.target?.value && productName ? false : true
                              )
                            }}
                            onChange={e => {
                              const { productName } = form.getFieldsValue()
                              setDisabledBtnUpload(
                                e && productName ? false : true
                              )
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={3}>
                        <Form.Item name="localPickup" label=" ">
                          <Checkbox onChange={e => setPickUp(e.target.checked)}>
                            {" "}
                            Local Pickup ?
                          </Checkbox>
                        </Form.Item>
                      </Col>
                      <Col span={3}>
                        <Form.Item name="labelUpload" label="Label Upload">
                          <Upload
                            beforeUpload={e => {
                              return false
                            }}
                            disabled={labelUploadFile || disabledBtnUpload}
                            onChange={e =>
                              onSaveLabel(e.fileList[e.fileList.length - 1])
                            }
                            showUploadList={false}
                            className="upload-list-inline"
                          >
                            <Button
                              icon={<UploadOutlined />}
                              disabled={labelUploadFile || disabledBtnUpload}
                              style={{ width: "100%" }}
                              className="btn btn_primary d-flex align-items-center justify-content-center"
                            >
                              Upload Label
                            </Button>
                          </Upload>
                        </Form.Item>
                      </Col>
                      <Col span={3}>
                        <Form.Item name="fileUpload" label="File Upload">
                          <Upload
                            accept=".jpg,.jpeg,.png,.tif,.tiff,.svg,.bmp,.gif"
                            // accept=".tif,.tiff"
                            beforeUpload={e => {
                              return false
                            }}
                            disabled={
                              fileList.length === 10 || disabledBtnUpload
                            }
                            onChange={e => {
                              setPageLoading(true)
                              e.file.type === "image/tiff"
                                ? handleUploadTiff(e.file, fileList.length)
                                : handleUploadImage(
                                    e.fileList[e.fileList.length - 1],
                                    e.file,
                                    e.fileList.length - 1
                                  )
                            }}
                            showUploadList={false}
                            listType="picture"
                            className="upload-list-inline"
                          >
                            <Button
                              icon={<UploadOutlined />}
                              disabled={
                                fileList.length === 10 || disabledBtnUpload
                              }
                              style={{ width: "100%" }}
                              className="btn btn_tertiary d-flex align-items-center justify-content-center"
                            >
                              Upload File
                            </Button>
                          </Upload>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={[16, 0]}>
                      <Col span={6}>
                        {labelUploadFile && (
                          <Tag
                            color="volcano"
                            size="large"
                            className="localLable pt-1 pb-1"
                            closeIcon={
                              <CloseCircleOutlined
                                style={{
                                  fontSize: 20,
                                }}
                              />
                            }
                            onClose={e => {
                              setLabelUploadFile(null)
                              e.preventDefault()
                            }}
                          >
                            {labelUploadFile}
                          </Tag>
                        )}
                      </Col>
                      {fileList.length > 0 && (
                        <>
                          <Col lg={24}>
                            <Form form={tableForm} component={false}>
                              <Table
                                title={() =>
                                  "List of Files Waiting to Be Uploaded"
                                }
                                bordered
                                size="small"
                                columns={columnsFileList}
                                dataSource={fileList}
                                rowKey={record => record.id}
                              />
                            </Form>
                          </Col>
                          <Col lg={15}>
                            <Form.Item
                              name="notes"
                              label="Notes"
                              style={{ width: "100%" }}
                            >
                              <TextArea rows={4} />
                            </Form.Item>
                          </Col>
                          <Col
                            lg={4}
                            style={{ display: "flex", alignItems: "end" }}
                          >
                            <Button
                              type="button"
                              style={{
                                display: "block",
                                width: "100%",
                                marginBottom: 30,
                              }}
                              className="btn btn-success "
                              disabled={
                                !uploadProgress.every(
                                  progress => progress === 100
                                )
                              }
                              onClick={() => {
                                setPageLoading(true)
                                form
                                  .validateFields()
                                  .then(() => {
                                    handleSave()
                                  })
                                  .catch(err => {
                                    setPageLoading(false)
                                    toastr.error(
                                      "Please fill in the required fields"
                                    )
                                    window.scrollTo({
                                      top: 0,
                                      behavior: "smooth",
                                    })
                                    Sounds.warning()
                                  })
                              }}
                            >
                              Save
                            </Button>
                          </Col>
                        </>
                      )}
                    </Row>
                  </Form>
                )}
                {(JSON.parse(localStorage.getItem("authUserV0"))?.roleId ===
                  1 ||
                  JSON.parse(localStorage.getItem("authUserV0"))?.roleId ===
                    7) && (
                  <Form form={searchForm} layout="vertical">
                    <Row gutter={[16, 16]}>
                      <Col lg={4}>
                        <Form.Item label="Customer" name="companyId">
                          <Select
                            allowClear
                            options={companyDataList}
                            onChange={e => {
                              getData()
                            }}
                            disabled={companyDataList.length === 0}
                          />
                        </Form.Item>
                      </Col>
                      <Col lg={3}>
                        <Form.Item label=" " name="pickUpSearch">
                          <Checkbox
                            onChange={e => setPickUpSearch(e.target.checked)}
                            checked={pickUpSearch}
                          >
                            {" "}
                            Local Pickup?
                          </Checkbox>
                        </Form.Item>
                      </Col>
                      <Col
                        lg={3}
                        className="d-flex align-items-center justify-content-start gap-2"
                      >
                        <Button
                          type="button"
                          className="btn btn-light d-flex align-items-center justify-content-start mt-1"
                          icon={<ClearOutlined />}
                          onClick={() => {
                            form.setFieldsValue({
                              companyId: "",
                            })
                            setPickUpSearch(false)
                            setData([])
                          }}
                        >
                          Clear
                        </Button>

                        <Button
                          type="button"
                          className="btn btn-primary d-flex align-items-center justify-content-start mt-1"
                          icon={<SearchOutlined />}
                          onClick={() => {
                            getData(null)
                          }}
                        >
                          Search
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )}
                <Table
                  bordered
                  locale={locale}
                  title={false}
                  columns={columns}
                  dataSource={data}
                  rowKey={record => record.id}
                  pagination={tableParams}
                  onChange={handleTableChange}
                />
              </Panel>
            </Collapse>
          ) : (
            <Alert
              message="Sistem Mesajı!"
              description="UvDtf işlemleriniz için sistem admin tarafından size ait ürün baz fiyatını belirlemesi gerekemektedir. İşlem yapmak için iletişime geçiniz!"
              type="error"
            />
          )}
        </Spin>{" "}
      </div>
    </React.Fragment>
  )
}

export default UVDTFList
