import React, { createContext, useReducer } from "react"

// Context oluşturma

const initialState = {
  filters: null,
  tableParams: {
    current: 1,
    pageSize: 10,
  },
}

const customReducer = (state, action) => {
  switch (action.type) {
    case "SET_TABLEPARAMS":
      return {
        ...state,
        tableParams: { ...action.payload },
      }
    case "SET_FILTERS":
      return {
        ...state,
        filters: { ...action.payload },
      }
    default:
      return state
  }
}

export const CustomContext = createContext()

export const CustomProvider = ({ children }) => {
  const [state, dispatch] = useReducer(customReducer, initialState)

  return (
    <CustomContext.Provider value={{ state, dispatch }}>
      {children}
    </CustomContext.Provider>
  )
}
